app.controller("calendarController",
    [
        "$scope", "$controller", "calendarService", "translationFlowClientService", "keyboardService", "sharedSessionService", "appConfig", "workflowThemeService", function ($scope, $controller, calendarService, translationFlowClientService, keyboardService, sharedSessionService, appConfig, workflowThemeService) {

            $scope.translationFlowClientService = translationFlowClientService;
            $scope.appConfig = appConfig;
            $scope.isGridCalendar = true;
            $scope.hasRows = false;

            $controller("gridController", { $scope: $scope });

            $scope.$on("$destroy", function () {
                keyboardService.unbind('ctrl+left');
                keyboardService.unbind('ctrl+right');
                keyboardService.unbind('left');
                keyboardService.unbind('right');
                keyboardService.unbind('up');
                keyboardService.unbind('down');
                keyboardService.unbind('insert');
                keyboardService.unbind('delete');
            });

            $scope.getSelectedMonthClasses = function () {
                if ($scope.expandedPinnedStep != null) {
                    return "";
                }
                else if (appConfig.APPLICATION_TYPE === 'WebClient') {
                    return "calendar-control-selected-month";
                }
                else {
                    return "";
                }
            };

            $scope.getColumnClasses = function () {
                if ($scope.expandedPinnedStep != null) {
                    return "col-xs-12 col-sm-12 col-md-12 col-lg-12";
                }
                else if (appConfig.APPLICATION_TYPE === 'WebClient') {
                    return "col-xs-12 col-sm-12 col-md-12 col-lg-4";
                }
                else {
                    return "col-xs-12 col-sm-12 col-md-12 col-lg-12";
                }
            };

            $scope.isLargeView = function () {
                if ($scope.expandedPinnedStep != null) {
                    return false;
                }
                else if (appConfig.APPLICATION_TYPE === 'WebClient') {
                    return true;
                }
                else {
                    return false;
                }
            };

            $scope.selectDateString = function (dateString, enabled, isReadonly) {
                var date = dateString.split("-");
                $scope.selectDate(date[0], date[1], date[2], enabled, isReadonly);
            };

            $scope.selectDate = function (day, month, year, enabled, isreadonly) {
                if (day && enabled) {
                    var toSend = {};
                    toSend.ControlIndex = $scope.cIndex;
                    toSend.RowIndex = $scope.rowIndex;
                    toSend.JobIdentity = $scope.JobIdentity;
                    toSend.Year = year;
                    toSend.Month = month;
                    toSend.Day = day;

                    if (month === $scope.c.Calendar.MonthNumber) {
                        for (var i = 0; i < $scope.Group.CalendarDays.length; i++) {
                            for (var j = 0; j < $scope.Group.CalendarDays[i].length; j++) {
                                $scope.Group.CalendarDays[i][j].Selected = false;
                                if (day === $scope.Group.CalendarDays[i][j].Day) {
                                    $scope.Group.CalendarDays[i][j].Selected = true;
                                }
                            }
                        }
                        calendarService.updateSelectedDate(toSend).then(function (result) {
                            $scope.c.Calendar.Blurbs = result.data.Blurbs;
                            $scope.c.Calendar.DayNumber = day;
                            $scope.c.Calendar.PreviousDay = result.data.PreviousDay;
                            $scope.c.Calendar.NextDay = result.data.NextDay;
                            $scope.c.Calendar.PreviousWeek = result.data.PreviousWeek;
                            $scope.c.Calendar.NextWeek = result.data.NextWeek;
                            $scope.c.Calendar.PreviousMonth = result.data.PreviousMonth;
                            $scope.c.Calendar.NextMonth = result.data.NextMonth;
                            $scope.c.Calendar.PreviousMonthNumber = result.data.PreviousMonthNumber;
                            $scope.c.Calendar.NextMonthNumber = result.data.NextMonthNumber;
                            $scope.c.Calendar.PreviousDayNumber = result.data.PreviousDayNumber;
                            $scope.c.Calendar.NextDayNumber = result.data.NextDayNumber;
                        }).catch(angular.noop);

                        $scope.setReadOnly(isreadonly);

                        $scope.gridOptions.columns = $scope.createColumns($scope.c.Columns, $scope.c.Rows);
                        $scope.grid.setOptions($scope.gridOptions);

                        $scope.filterGrid(day, month, year);
                    } else {
                        $scope.displayLoadingBar = true;
                        calendarService.calculateCalendar(toSend).then(function (result) {
                            $scope.c.Calendar = result.data;
                            $scope.initialize(true);
                            $scope.displayLoadingBar = false;
                        }).catch(angular.noop);
                    }
                };
            };

            $scope.setReadOnly = function(isreadonly) {
                $scope.IsReadOnly = isreadonly;

                if (isreadonly) {
                    $scope.gridOptions.editable = false;
                } else {
                    $scope.gridOptions.editable = { createAt: "bottom" };
                }
            }

            $scope.filterGrid = function (day, month, year) {
                if (day) {
                    var filter = { logic: "and", filters: [] };

					//we start from 2 (first column is Id, second is CalendarOrderId
					//if grid allows delete, we should increment counter to get proper column
					var colId = 2;

					if ($scope.c.AllowDelete)
						colId += 1;
						
                    var value = kendo.toString(kendo.parseDate(new Date(year, month - 1, day)), "yyyy-MM-dd");

                    filter.filters.push({
						field: $scope.columns[colId].field,
						value: value,
						operator: "startswith"
                    });

                    $scope.gridOptions.dataSource.filter(filter);
                    $scope.gridOptions.dataSource.sort({
                        field: "CalendarOrderId",
                        dir: "asc"
                    });
                    $scope.hasRows = $scope.gridOptions.dataSource.total() > 0;
                }
            };

            $scope.getUnderlineStyle = function (item) {
                if (item.Underline) {
                    return {
                        'width': '25px',
                        'border-bottom': '3px solid ' + item.Underline
                    };
                } else {
                    return {

                    };
                }
            };

            $scope.getStarringStyle = function (item) {
                if (item.Starring) {
                    return {
                        'color': item.Starring
                    };
                } else {
                    return {};
                }
            };

            $scope.getCalendarItemClass = function (item) {

                var toReturn = "";

                if (item.Enabled && item.IsReadOnly) {
                    toReturn += "calendar-control-item-readOnly-enabled";
                }
                else if (item.Enabled) {
                    toReturn += "calendar-control-item-enabled";
                }

                if (item.Today && !item.Enabled) {
                    toReturn += " calendar-control-item-today-disabled";
                }
                else if (item.Today) {
                    toReturn += " calendar-control-item-today";
                }
                else if (item.IsReadOnly && item.Selected) {
                    toReturn += " calendar-control-item-readOnly";
                }
                else if (item.Selected) {
                    toReturn += " calendar-control-item-selected";
                }

                return toReturn;
            };


            $scope.switch = function (day, month, year) {
                if (!$scope.displayLoadingBar) {
                    var toSend = {};
                    toSend.RowIndex = $scope.rowIndex;
                    toSend.ControlIndex = $scope.cIndex;
                    toSend.JobIdentity = $scope.JobIdentity;
                    toSend.Year = year;
                    toSend.Month = month;
                    toSend.Day = day;
                    toSend.SkipSelectedDate = true;
                    $scope.displayLoadingBar = true;
                    calendarService.calculateCalendar(toSend).then(function (result) {
                        $scope.c.Calendar = result.data;
                        $scope.initialize(true);
                        $scope.displayLoadingBar = false;
                    }).catch(angular.noop);
                }
            };

            $scope.switchToDate = function (dateString) {
                var date = dateString.split("-");
                $scope.switch(date[0], date[1], date[2]);
            };

            // used for grid to reload
            $scope.refreshCalendar = function (deletedRow) {
                $scope.displayLoadingBar = true;

                var toSend = {};
                toSend.RowIndex = $scope.rowIndex;
                toSend.ControlIndex = $scope.cIndex;
                toSend.JobIdentity = $scope.JobIdentity;
                toSend.SkipSelectedDate = true;
                $scope.displayLoadingBar = true;

                calendarService.calculateCalendar(toSend).then(function (result) {
                    $scope.c.Calendar = result.data;
                    $scope.initialize(deletedRow);
                    $scope.displayLoadingBar = false;
                }).catch(angular.noop);
            }

            $scope.initialize = function (refreshGrid) {
                $scope.Group = {};
                $scope.Group.PreviousCalendarDays = [];
                $scope.Group.PreviousCalendarDays.push([]);
                $scope.Group.CalendarDays = [];
                $scope.Group.CalendarDays.push([]);
                $scope.Group.NextCalendarDays = [];
                $scope.Group.NextCalendarDays.push([]);
                $scope.c.Calendar.TranslatedPreviousMonth = $scope.monthNames[$scope.c.Calendar.PreviousMonthNumber - 1];
                $scope.c.Calendar.TranslatedMonth = $scope.monthNames[$scope.c.Calendar.MonthNumber - 1];
                $scope.c.Calendar.TranslatedNextMonth = $scope.monthNames[$scope.c.Calendar.NextMonthNumber - 1];

                $scope.createMonth($scope.c.Calendar.FirstDayInMonth, $scope.c.Calendar.CalendarDays, $scope.Group.CalendarDays);
                $scope.createMonth($scope.c.Calendar.FirstDayInPreviousMonth, $scope.c.Calendar.PreviousCalendarDays, $scope.Group.PreviousCalendarDays);
                $scope.createMonth($scope.c.Calendar.FirstDayInNextMonth, $scope.c.Calendar.NextCalendarDays, $scope.Group.NextCalendarDays);

                var selectedDay = null;
                for (var i = 0; i < $scope.c.Calendar.CalendarDays.length; i++) {
                    if ($scope.c.Calendar.DayNumber === $scope.c.Calendar.CalendarDays[i].Day) {
                        selectedDay = $scope.c.Calendar.CalendarDays[i];
                        break;
                    }
                }

                if (selectedDay) {
                    var updateReadOnly = 
                        $scope.IsReadOnly != selectedDay.IsReadOnly || // if they are not the same
                        $scope.IsReadOnly == undefined || // during init
                        !selectedDay.Enabled; // or if a day is completely disabled, we do not wish anything to be possible to be edited.
                    if (updateReadOnly) {
                        $scope.setReadOnly(selectedDay.IsReadOnly || !selectedDay.Enabled);
                        $scope.grid.setOptions($scope.gridOptions);
                    }
                }

                if (refreshGrid) // to prevent the grid to refresh when you changed a CELL value. But still refresh on delete
                    $scope.filterGrid($scope.c.Calendar.DayNumber, $scope.c.Calendar.MonthNumber, $scope.c.Calendar.Year);

                $scope.themeColor = workflowThemeService.getColor();
                $scope.selected = false;

                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            };

            $scope.createMonth = function (firstDayInMonth, calendarDays, groupCalendarDays) {
                for (var j = 0; j < $scope.dayNumbers[firstDayInMonth] - 1; j++) {
                    groupCalendarDays[groupCalendarDays.length - 1].push({});
                }

                var q = $scope.dayNumbers[firstDayInMonth] - 1;

                for (var i = 0; i < calendarDays.length; i++) {
                    groupCalendarDays[groupCalendarDays.length - 1].push(calendarDays[i]);

                    if (q === 6) {
                        q = 0;
                        groupCalendarDays.push([]);
                    } else {
                        q++;
                    }
                }
            };

            $.getScript($.sharedScriptsDir +
                "/cultures/kendo.culture." +
                sharedSessionService.getLanguageCode() +
                ".min.js").then(function () {

                    var culture = kendo.culture();
                    $scope.dayNames = angular.copy(kendo.cultures[sharedSessionService.getLanguageCode()].calendars.standard.days.namesAbbr);
                    $scope.monthNames = angular.copy(kendo.cultures[sharedSessionService.getLanguageCode()].calendars.standard.months.names);

                    if (culture.calendar.firstDay) {
                        $scope.dayNumbers = {
                            "Monday": 1,
                            "Tuesday": 2,
                            "Wednesday": 3,
                            "Thursday": 4,
                            "Friday": 5,
                            "Saturday": 6,
                            "Sunday": 7
                        };
                        $scope.dayNames.push($scope.dayNames.shift());

                    } else {
                        $scope.dayNumbers = {
                            "Sunday": 1,
                            "Monday": 2,
                            "Tuesday": 3,
                            "Wednesday": 4,
                            "Thursday": 5,
                            "Friday": 6,
                            "Saturday": 7
                        };
                    }

                    $scope.initialize(true);
                });

            keyboardService.bind('left',
                function () {
                    $scope.switchToDate($scope.c.Calendar.PreviousDay);
                });

            keyboardService.bind('right',
                function () {
                    $scope.switchToDate($scope.c.Calendar.NextDay);
                });

            keyboardService.bind('ctrl+left',
                function () {
                    $scope.switchToDate($scope.c.Calendar.PreviousMonth);
                });

            keyboardService.bind('ctrl+right',
                function () {
                    $scope.switchToDate($scope.c.Calendar.NextMonth);
                });
            keyboardService.bind('up',
                function () {
                    $scope.switchToDate($scope.c.Calendar.PreviousWeek);
                });

            keyboardService.bind('down',
                function () {
                    $scope.switchToDate($scope.c.Calendar.NextWeek);
                });

            keyboardService.bind('insert',
                function () {
                    if (!$scope.displayLoadingBar && $scope.c.AllowAdd && !$scope.IsReadOnly) {
                        $scope.addRow();
                    }
                });

            keyboardService.bind('delete',
                function () {
                    if (!$scope.displayLoadingBar && $scope.c.AllowDelete && !$scope.IsReadOnly) {
                        $scope.removeSelectedRow();
                    }
                });
        }
    ]);