app.controller("confirmDialogController",
    [
        "$scope", "$rootScope", "$uibModalInstance",
        "translationFlowClientService", "dialogResult",
        function($scope,
            $rootScope,
            $uibModalInstance,            
            translationFlowClientService,
            dialogResult) {
            
            $scope.initialize = function() {
                $scope.translationFlowClientService = translationFlowClientService;
                $scope.message = $scope.translationFlowClientService.getValueFromDictionary("RemoveSubtaskItem", "Remove this item?");
            };

            $scope.initialize();

            $scope.yes = function() {
                dialogResult(true);
                $uibModalInstance.close();
                
            };

            $scope.no = function() {
                dialogResult(false);
                $uibModalInstance.close();
            };
        }
    ]);