app.controller('BaseHomeController', [
    "$scope", "$rootScope", "stateService", "homeStateService", "$uibModal", "workflowService", "executionService", "authenticationService",
    "sharedSessionService", "workflowThemeService", "imageService", "progressService", "$cookies", 'translationFlowClientService', 'featuresService', '$q', "logoService", "sharedThemeService",
    function ($scope, $rootScope, stateService, homeStateService, $uibModal, workflowService, executionService, authenticationService, sharedSessionService, workflowThemeService, imageService, progressService, $cookies, translationFlowClientService, featuresService, $q, logoService, sharedThemeService) {

        $scope.showKeyboardModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: $.sharedAppDir + "/views/keyboardHints.html",         
                backdrop: 'static',
                keyboard: true,
                controller: ['$scope', function ($scope) {
                    $scope.closeInboxModal = function () {
                        modalInstance.close();
                    };
                }]
            });
        };

        $scope.helpLink = function () {
            return $.helpLink;
        };

        $scope.initialize = function () {
            $scope.authenticationService = authenticationService;
            $scope.sharedSessionService = sharedSessionService;
            $scope.imageService = imageService;

            $scope.translationFlowClientService = translationFlowClientService;
            $scope.featuresService = featuresService;

            var translationsEmpty = $scope.translationFlowClientService.areTranslationsEmpty();
            var featuresEmpty = $scope.featuresService.areFeaturesEmpty();

            if (translationsEmpty || featuresEmpty) {
                var promises = [];
                promises.push($scope.translationFlowClientService.getAll(sharedSessionService.getLanguageCode()));
                promises.push($scope.featuresService.getAllFeatureFlags());

                $q.all(promises)
                    .then(function () {
                        $scope.showScreen = true;
                    }).catch(angular.noop);
            }
            else {
                $scope.showScreen = true;
            }

        };

        $scope.initialize();
    }]);

