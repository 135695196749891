app.controller("RegularExecutionController", [
    "$scope", "stateService", "executionService", "authenticationService", '$routeParams', 'workflowThemeService', '$location', "sharedSessionService", function ($scope, stateService, executionService, authenticationService, $routeParams, workflowThemeService, $location, sharedSessionService) {
                
        $scope.showExecutionView = false;

        $scope.initialize = function () {
            
            $scope.workflowThemeService = workflowThemeService;
            
            if ($routeParams.workflowIdentifier) {
                executionService.start($routeParams.workflowIdentifier).then(function (result) {
                    $scope.workflowThemeService.setColor(result.data.WorkflowHeader.ThemeColor);
                    $scope.showExecutionView = true;
                    stateService.go($scope, 'execution', { executionIdentity: result.data.JobIdentity, isHandover: false, name: text, workflowTitle: text, workflowIcon: icon });
                });
            }
        };

        $scope.initialize();
    }
])