app.controller('numericController', ['$scope', 'sharedSessionService', function ($scope, sharedSessionService) {
    $scope.numericOptions = {
        format: "{0:###########.############}",
        decimals: 10,
        spinners: true,
        min: -999999999999999, // Kendo supports 16 digits
        max: 999999999999999,
        round: false
    };

    //USA: 1.5 = 15/10
    //Swe: 1,5 = 15/10
    var validate = function (val) {
        var valid = val.length > 0 || $scope.c.AllowEmpty;
        if (!valid)
            $scope.c.numeric.element.addClass("invalid");
        else
            $scope.c.numeric.element.removeClass("invalid");
    };

	$scope.Init = function () {
		
		var culture = sharedSessionService.getCulture();

        // Server sends default value in invarient format, so if local format can't interpret that 
        // we get something that is wrong, ex 8.8 in culture "da" should show 8,8 but shows 88 if not converted. 
        if (culture && !isNaN(parseFloat($scope.c.Value)) && isFinite($scope.c.Value)) {
            $scope.c.Value = $scope.c.Value.toLocaleString(culture, { 'useGrouping': false, 'maximumFractionDigits': 10 });
        }
    };

    // Lets trigger change so next button is enabled when typing.
    $scope.$on("kendoWidgetCreated", function (event, widget) {
        if (widget === $scope.c.numeric) {
            var input = $(this);
            
            //find input and focus on it
            if ($scope.c.FocusOnMe) {
                var elem = widget.wrapper.find("input[type='text']")[0];
                elem.focus();
            }
            widget.wrapper.find("[type='text']").bind("focus",
                function() {

                    clearTimeout(input.data("selectTimeId")); //stop started time out if any

                    var selectTimeId = setTimeout(function() {
                        input.select();
                    });
                    input.data("selectTimeId", selectTimeId);
                    var val = $scope.c.numeric.element.val();
                    validate(val);

                }).blur(function(e) {
                clearTimeout($(this).data("selectTimeId")); //stop started timeout
            }).keyup(function(k) {
                var val = $scope.c.numeric.element.val();
                validate(val);
                $scope.c.numeric.trigger("change");
            });
        }
    }); 

    $scope.Init();
}])