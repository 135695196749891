app.controller("ThankYouController", [
    "$scope", 'translationFlowClientService', 'authenticationService', 'stateService', function ($scope, translationFlowClientService, authenticationService, stateService) {

        $scope.initialize = function () {         

            authenticationService.logoutIfPublic();

            // Allow Portal to attach a end callback. This will close dialog or portlet.
            var params = stateService.getStateParams($scope);
            if (params.endCallback) {
                params.endCallback();
            }
        };
        $scope.initialize();
    }
])