app.controller('verbSubTaskController', ['$scope', 'stateService', 'executionService', 'workflowThemeService', 'translationFlowClientService', 'imageService', 'userStepDataService', function ($scope, stateService, executionService, workflowThemeService, translationFlowClientService, imageService, userStepDataService) {

    $scope.run = function (text,canRunSubtask) {
        if (canRunSubtask) {
            var data = {};
            data.WebCompletedExecution = {};

            data.JobIdentity = $scope.$parent.$parent.JobIdentity;
            data.SubTaskIdentity = $scope.$parent.item.Identity;
            data.WebCompletedExecution.Controls = userStepDataService.getLimitedUserStepData($scope.model.activeStep.Rows);
            data.WebCompletedExecution.Identifier = $scope.model.activeStep.Identifier;

            executionService.startSubworkflow(data).then(function (result) {
                stateService.go($scope, 'execution', { executionIdentity: result.data.JobId, isHandover: false, name: text });
            }).catch(angular.noop);
        }
       
    }

    $scope.initialize = function () {
        $scope.workflowThemeService = workflowThemeService;
        $scope.imageService = imageService;
        $scope.themeColor = workflowThemeService.getColor();
        $scope.translationFlowClientService = translationFlowClientService;
    };

    $scope.initialize();
}])