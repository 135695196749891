app.controller('menuSelectionController', ['$scope', function ($scope) {

    $scope.$on("kendoWidgetCreated", function (event, widget) {
        if ($scope.c.Type== 'menuSelection') {                        
            var input = $(this);
            //find input and focus on it
            if ($scope.c.FocusOnMe) {                
                widget.focus();
            }
        }
    });

    $scope.valueMapper = function (options) {
        options.success(options.value);
    };
}]);