app.controller("sharedThemeController", ["$scope", "sharedThemeService", function ($scope, sharedThemeService) {

    sharedThemeService.getSharedTheme().then(function (result) {
        if (result) {
            $scope.colorHexCodeBanner = result.data.ColorHexCodeBanner;
            $scope.colorHexCodeHeader = result.data.ColorHexCodeHeader;
            $scope.colorHexCodeButton = result.data.ColorHexCodeButton;
            $scope.colorHexCodeButtonAccent  = adjust(result.data.ColorHexCodeButton, -40)
        }
        else {
            // if no result is returned the default colors are applied to avoid white/transparent elements
            $scope.colorHexCodeBanner = "#FFFFFF";
            $scope.colorHexCodeHeader = "#666666";
            $scope.colorHexCodeButton = "#00AAE5";
            $scope.colorHexCodeButtonAccent  = adjust('#00AAE5', -40)
        }

        /* makes an accent color used for hover/active states*/
        function adjust(color, amount) {
            var num = color.replace(/^#/, '');
            num = parseInt(num, 16);
            var r = (num >> 16) + amount;
            var b = ((num >> 8) & 0x00FF) + amount;
            var g = (num & 0x0000FF) + amount;
            var newColor = g | (b << 8) | (r << 16);
            return '#' + newColor.toString(16);  
        }

    })
}])