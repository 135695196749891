app.controller('datePickerController', ['$scope', 'sharedSessionService', function ($scope, sharedSessionService) {

    var validate = function(currentText, kendoValue) {
        if (kendoValue) {// kendo verified the value is correct, do no invalidate
            return true;
        } else if ($scope.c.AllowEmpty) { // we allow empty
            return true; // its true even if it is empty or not
        } else { // we dont allow empty
            return currentText && currentText.length > 0;
        }    
    };

    $scope.textValidation = function (c) { 
        var letters = /[a-zA-Z]/g; 
        var text = c == null ? "" : c.trim();

        if (text && typeof text == 'string' && text.length > 0) {  // if the text is not empty, and it is text
            if (text.match(letters)) {  // there are letters somewhere 
                $scope.c.validationClass = "invalid";
                return false;
            } else { // there are no letters
                var date = kendo.parseDate(text, "MM-dd-yyyy");
                if (date) {  // known default correct format
                    $scope.c.validationClass = "";
                    $scope.c.Value = text;
                    return true;
                } else { // not "MM-dd-yyyy format"
                    var localeDate = $scope.format != null ? kendo.parseDate(text, $scope.format) : false;
                    if (localeDate) { // try handle date with "locale date"
                        var dateString = localeDate.getFullYear() + "-" + ("0" + (localeDate.getMonth() + 1)).slice(-2) + "-" + ("0" + localeDate.getDate()).slice(-2);
                        $scope.c.validationClass = "";
                        $scope.c.Value = dateString;
                        return true;
                    } else {
                        $scope.c.validationClass = "invalid";
                        return false;
                    }
                } 
            }
        } else { // if the text is empty
            if ($scope.c.AllowEmpty) { // empty is allowed
                $scope.c.validationClass = "";
                $scope.c.Value = "";
                return true;
            } else { // empty is not allowed
                $scope.c.validationClass = "invalid";
                return false;
            }
        }  
    }

    $scope.onChange = function (value) {
        if ($scope.c.AllowEmpty && (value == null || value.length === 0)) {
            $scope.c.validationClass = "";
            $scope.c.Value = "";
        } else {
            $scope.textValidation(value);
        }
    };

    $scope.onBlur = function () {
        // if validation class is not empty when you leave focus, we reset it to the previous
        // most valid value, so that it becomes "valid" again.
        if ($scope.c.validationClass !== "")
            $scope.setDisplayValue();
    }

    $scope.setDisplayValue = function () {
        if ($scope.c.Value.length > 0) {
            var date = kendo.parseDate($scope.c.Value, "yyyy-MM-dd");
            $scope.displayValue = date.toLocaleDateString($scope.culture);
            $scope.c.validationClass = "";
        } else {
            $scope.displayValue = "";
        }
    }
    
    $scope.initLocaleDateFormat = function () {
        var date = new Date(1999, 11, 24);
        var value = date.toLocaleDateString($scope.culture);
        var splits = value
            .split(/(\d+)/)
            .filter(function(x) {
                return x != null && x.length > 0
            });
        
        var month = false;
        var year = false;
        var day = false;
        for (var i in splits) {
            if (splits[i] === "12") {
                splits[i] = "MM";
                month = true;
            } else if (splits[i] === "24") {
                splits[i] = "dd";
                day = true;
            }
            else if (splits[i] === "1999") {
                splits[i] = "yyyy";
                year = true;
            }
        }
        
        $scope.format = month && year && day ? splits.join('') : null;
    }

    $scope.initialize = function () {
        $scope.culture = sharedSessionService.getCulture();
        $scope.setDisplayValue();
        
        $scope.initLocaleDateFormat();

        $scope.dateOptions = {
            change: function () {
                if (validate($scope.displayValue, this.value())) { // if validation returns true
                    if (this.value()) {  // the text is in a kendo value format
                        var d = this.value();
                        var datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                        $scope.c.Value = datestring;
                        $scope.c.validationClass = "";
                    } else { // text is a user entered format.
                        $scope.onChange($scope.displayValue);
                    }
                } else {
                    $scope.c.validationClass = "invalid";
                }
                $scope.$apply();
            }
        };
    };

    $scope.initialize();
}])