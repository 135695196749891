app.controller('listSelectionController', ['$scope', '$rootScope', '$filter', 'keyboardService', '$timeout', 'appConfig', 'translationFlowClientService', 'ListSelectionRowService', function ($scope, $rootScope, $filter, keyboardService, $timeout, appConfig, translationFlowClientService, ListSelectionRowService) {

    var SUPPORTED_STYLES = ["RED", "GREEN", "YELLOW", "PURPLE", "BLUE"];

    $scope.getRow = function (globalIndex) {
        return $scope.rowService.rowByRowIndex(globalIndex);
    };

    $scope.highlightNextRow = function () {
        $scope.HighlightedIndex = $scope.rowService.getNextRowIndex($scope.HighlightedIndex);
    };
    $scope.highlightPreviousRow = function () {
        $scope.HighlightedIndex = $scope.rowService.getPreviousRowIndex($scope.HighlightedIndex);
    };

    $scope.onListSelectionRowClick = function (selectedIndex) {
        if (!$scope.c.MultiSelect) {
            $scope.c.SelectedIndices = [];
        }

        if ($scope.c.SelectedIndices.indexOf(selectedIndex) === -1) {
            $scope.c.SelectedIndices.push(selectedIndex);
        } else {
            $scope.c.SelectedIndices.splice($scope.c.SelectedIndices.indexOf(selectedIndex), 1);
        }

        if (!$scope.c.MultiSelect) {
            $scope.activated = false;
            $scope.moveToNextField();
        }

        var selectedRow = $scope.getRow(selectedIndex);
        $scope.HighlightedIndex = selectedIndex;
        $scope.selectedRowLargeText = selectedRow.LargeText;
        $scope.selectedRowSmallText = selectedRow.SmallText;
    };

    $scope.isSelected = function (selectedIndex) {
        return $scope.c.SelectedIndices.indexOf(selectedIndex) >= 0;
    };

    $scope.isHighlighted = function (selectedIndex) {
        return $scope.HighlightedIndex === selectedIndex;
    };

    $scope.onListSelectionButtonClick = function () {
        $scope.activated = true;
        $scope.hasFocus = true;
        setTimeout(function() {
            var p = document.getElementById($scope.searchFieldKey);
            if (p != null) { // just validation check.
                p.focus();
                p.select();
            }
        }, 250); // need to do it by a timeout, since it cannot focus during "render"
    };

    $scope.inSelectionMode = function () {
        return $scope.activated === true;
    };

    $scope.itemStyleClass = function () {
        var r = $scope.c.SelectedIndices.length > 0 ? $scope.getRow($scope.c.SelectedIndices[0]) : null;
        var res = r && r.Style && SUPPORTED_STYLES.indexOf(r.Style.toUpperCase()) > -1 ? "button-style-" + r.Style.toLowerCase() : "button-style-gray";
        return res;
    };

    $scope.$on("$destroy", function () {
        keyboardService.unbind('enter');
        keyboardService.unbind('up');
        keyboardService.unbind('down');
    });

    $scope.onFocus = function () {
        $scope.hasFocus = true;
    };

    $scope.generateControlRandomKey = function () {
        return Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
    };

    $scope.filterData = function (filter) {
        var searchTerm = filter.toLowerCase();
        $scope.rowService.filter(function (row) {
            return row.LargeText.toLowerCase().indexOf(searchTerm) >= 0 ||
                    row.SmallText.toLowerCase().indexOf(searchTerm) >= 0;
            }, function (group) {
                return group.Text.toLowerCase().indexOf(searchTerm) >= 0;
            });
    };

    $scope.showAllRows = function () {
        $scope.rowService.disableSubsetLimit();
    };

    $scope.moveToNextField = function () {
        var elem = document.getElementById($scope.searchFieldKey);
        var pageElems = document.querySelectorAll('input,select');

        var len = pageElems.length;
        var focusNext = false;

        for (var i = 0; i < len; i++) {
            var pe = pageElems[i];
            if (focusNext) {
                if (pe.style.display !== 'none') {
                    angular.element(pe).focus();
                    break;
                }
            } else if (pe === elem && (i + 1 < len)) {
                focusNext = true;
            }
        }
        $scope.hasFocus = false;

        if (focusNext === false)
            $rootScope.$broadcast(appConfig.Events.ENTER_KEY_PRESSED, "true");
    };

    $scope.initialize = function () {
        $scope.controlKey = $scope.generateControlRandomKey();
        $scope.searchFieldKey = $scope.generateControlRandomKey();
        $scope.hasFocus = false;
        $scope.rowService = ListSelectionRowService.init($scope.c.Groups);
        $scope.HighlightedIndex = null;

        var last = $scope.c.SelectedIndices.slice(-1).pop();
        if (last)
            $scope.HighlightedIndex = last;

        $scope.selectedRowLargeText = translationFlowClientService.get('SelectionHint');
        $scope.selectedRowSmallText = translationFlowClientService.get('SelectionHint');

        if ($scope.c.SelectedIndices.length > 0) {
            var row = $scope.getRow($scope.c.SelectedIndices[0]);
            $scope.selectedRowLargeText = row.LargeText;
            $scope.selectedRowSmallText = row.SmallText;
        }

        $scope.activated = $scope.c.AutoShow || $scope.c.MultiSelect;
        $scope.filterValue = "";
    };

    $timeout(
        function () {
            var listSelectionOptions = {
                target: $scope.controlKey
            };

            keyboardService.bind('enter', function () {
                if ($scope.rowService.currentGroups.length === 1 && $scope.rowService.currentGroups[0].Rows.length === 1) {
                    $scope.onListSelectionRowClick($scope.rowService.currentGroups[0].Rows[0].RowIndex);
                }
                else if ($scope.HighlightedIndex !== null && $scope.hasFocus) {
                    $scope.onListSelectionRowClick($scope.HighlightedIndex);
                }
            }, listSelectionOptions);

            keyboardService.bind('down', function () {
                $scope.highlightNextRow();
                $scope.scrollToIndex();
            }, listSelectionOptions);

            keyboardService.bind('up', function () {
                $scope.highlightPreviousRow();
                $scope.scrollToIndex();
            }, listSelectionOptions);
        }
    );
    
    $scope.scrollToIndex = function () {
        if ($scope.HighlightedIndex != null) {
            var item = document.getElementById($scope.controlKey + '-' + $scope.HighlightedIndex);
            // 52 magical number :) it just works good. on another note - its to offset the scroll so
            // we can see around the selected  / highlighted element.
            document.getElementById($scope.controlKey + '-wrapper').scrollTop = item.offsetTop - 52; 
        }
    };

    $scope.$on("$destroy", function(event) {
        keyboardService.unbind('enter');
        keyboardService.unbind('down');
        keyboardService.unbind('up');
    });

    $scope.initialize();
}])