app.controller("executionController",
     ["$scope", "$rootScope", "stateService", "$uibModal", "$timeout", "$cookies", "$templateCache", "executionService",
    "sharedSessionService", "workflowThemeService", "translationFlowClientService",
         "webExceptionHandlingService", "userStepDataService", "imageService", "historyService", "workflowService", "$location", 'keyboardService', '$window', '$injector', 'appConfig', 'translationFlowClientService', 'featuresService', '$q',
    function ($scope, $rootScope,
        stateService,
        $uibModal,
        $timeout,
        $cookies,
        $templateCache,
        executionService,
        sharedSessionService,
        workflowThemeService,
        translationFlowClientService,
        webExceptionHandlingService,
        userStepDataService,
        imageService,
        historyService,
        workflowService,
        $location,
        keyboardService,
        $window, 
        $injector,
        appConfig,
        translationFlowClientService,
        featuresService,
        $q
        ) {

        var portletId = 0;
        $scope.executionWidth = 70;
        $scope.preferedWidth = 0;

        angular.element($window).bind('resize', function () {
            $scope.executionWidth = $scope.getPreferedWidthInner($scope.model.activeStep);
            $scope.$digest();
        })

        $scope.show = false;

        $scope.$on("changeStateCompleted",
            function () {
                $scope.initialize();
            });

        $scope.canExit = function () {
            return stateService.canExit($scope);
        };

        $scope.moveBack = function (stepId) {
            var data = {};

            data.StepId = stepId;
            data.JobIdentity = $scope.JobIdentity;
            executionService.moveBack(data)
                .then(function (result) {
                    var state = result.data;
                    if (state.CanMoveBack) {
                        $scope.setState(state);
                    } else {
                        $scope.webExceptionHandlingService
                            .displayWarningNotification("It is not possible to move back to this step");
                    }
                }).catch(angular.noop);
        };

        $scope.validateControls = function () {
            //negate due to using ng-enabled  
            var result = userStepDataService.validateControls($scope.model.activeStep.Rows);
	        return !result;
        };

        $scope.generateControlRandomKey = function () {
            var result = Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);

            return result;
        };

        $scope.calculateControlWidth = function (controlsInRow) {
            //Small = 1 column (<660), Medium = 2 (660- 1019) columns and Large = 4 Columns then
            var windowWidth = $window.innerWidth;
            var sizeForXs = 12;

            if (windowWidth > 660)
                if (controlsInRow > 1)
                    sizeForXs = 6;
                else
                    sizeForXs = 12;
            if (windowWidth>1019)
                sizeForXs=12 / controlsInRow;
          
            var size = 12 / controlsInRow;

            var result = 'col-xs-' + sizeForXs + ' col-md-' + size;
            return result;
        };

        $scope.executionContainerClicked = function () {
            $scope.historyService.hideHistoryListAndBroadcast();
        };

        function gridUpdatingTimeout(exitIndex) {
            $scope.moveNextTimeout = setTimeout(function () {
                if ($rootScope.IsGridUpdating) {
                    gridUpdatingTimeout(exitIndex);
                } else {
                    $scope.moveNext(exitIndex);
                    $scope.moveNextTimeout = undefined;
                }
            }, 100);
        }

        $scope.moveNext = function (exitIndex) {
            if (exitIndex === undefined)
                return;

            // if we are updating the grid, we have to await it
            // otherwise it can raise an error if a value has not
            // been completely updated yet.
            if ($rootScope.IsGridUpdating) {
                // create and set a timeout if we do not have one
                // to prevent so we are not able to spam the button
                if (!$scope.moveNextTimeout)
                    gridUpdatingTimeout(exitIndex);
                return;
            }

            var data = {};
            data.WebCompletedExecution = {};
            data.JobIdentity = $scope.JobIdentity;
            data.ExitIndex = exitIndex;

            data.WebCompletedExecution.Controls = userStepDataService.getLimitedUserStepData($scope.model.activeStep.Rows);
            data.WebCompletedExecution.Identifier = $scope.model.activeStep.Identifier;

            if ($scope.sendingData || $scope.model.IsCompleted)
                return;

            $scope.sendingData = true;
            executionService.moveNext(data)
                .then(function (result) {
                    var newState = result.data;
                    if (newState.IsCompleted) {
                        $scope.model.IsCompleted = true;
                        angular.forEach(newState.NewHistorySteps,
		                    function (step) {
			                    if (step.StepType === 'checkPoint') {
				                    $scope.showPopup(step.Text, step.Title);
			                    }
		                    });

                        executionService.resetCurrentWorkflowState(portletId);

                        var toSend = {};
                        toSend.JobIdentity = $scope.JobIdentity;
                        executionService.cancelJob(toSend).then(function() {
                            if (newState.LinkedWorkflowIdentity) {
                                executionService.start(newState.LinkedWorkflowIdentity, true)
                                    .then(function (result) {
                                        stateService.goAndReplace($scope,
                                            "execution",
                                            { executionIdentity: result.data.JobIdentity, isHandover: false });
                                        workflowThemeService.setColor(result.data.WorkflowHeader.ThemeColor);
                                        $scope.themeColor = workflowThemeService.getColor();
                                        $scope.workflowIcon = result.data.WorkflowHeader.IconIdentifier;
                                        $scope.workflowTitle = result.data.WorkflowHeader.Title;
    
                                        //remember current executed workflow
                                        executionService.setCurrentWorkflowState(
                                            result.data.JobIdentity,
                                            result.data.WorkflowHeader,
                                            undefined,
                                            portletId
                                            );
                                    });
                            }
							else if (stateService.canGoBack($scope)) {
	                            executionService.resetCurrentInboxWorkflowState();
                                stateService.goBack($scope);
                            }
                            else if ($scope.executeOnly) {
                                stateService.go($scope, 'thankYou', stateService.getStateParams($scope));
                            }
                            else
                                $scope.exit();
                        }).catch(angular.noop);
                    } else if (newState.CanMoveForward || newState.IsOnHold) {
                        angular.forEach(newState.NewHistorySteps,
                            function (step) {
                                if (step.StepType == 'checkPoint') {
                                    $scope.showPopup(step.Text, step.Title);
                                }
                            });

                        $scope.setState(newState);
                    } else {
                        $scope.webExceptionHandlingService.displayWarningNotification("To go forward, please complete the current step");
                    }
                }).catch(angular.noop).finally(function() {
                    $scope.sendingData = false;
                });
        };

        $scope.handleIsCompleted = function (newState) {
            var toSend = {};
            toSend.JobIdentity = $scope.JobIdentity;
            executionService.cancelJob(toSend).then(function () {
                if (newState.LinkedWorkflowIdentity) {
                    executionService.start(newState.LinkedWorkflowIdentity, true)
                        .then(function (result) {
                            stateService.goAndReplace($scope, "execution", { executionIdentity: result.data.JobIdentity, isHandover: false });
                            workflowThemeService.setColor(result.data.WorkflowHeader.ThemeColor);

                            $scope.themeColor = workflowThemeService.getColor();
                            $scope.workflowIcon = result.data.WorkflowHeader.IconIdentifier;
                            $scope.workflowTitle = result.data.WorkflowHeader.Title;

                            //remember current executed workflow
                            executionService.setCurrentWorkflowState(result.data.JobIdentity,
                                result.data.WorkflowHeader, undefined, portletId);

                        });
				} else if (stateService.canGoBack($scope)) {
						executionService.resetCurrentInboxWorkflowState();
		                stateService.goBack($scope);
	                }
	                else if ($scope.executeOnly) {
		                stateService.go($scope, 'thankYou', stateService.getStateParams($scope));
	                }
	                else
		                $scope.exit();
                    //stateService.goBack($scope);
            }).catch(angular.noop);
        };

        $scope.refresh = function (index) {
            var data = {};
            data.JobIdentity = $scope.JobIdentity;

            executionService.refresh(data)
                .then(function (result) {
                    var newState = result.data;
                    if (!newState.IsOnHold) {
                        if (newState.IsCompleted) {

                            $scope.handleIsCompleted(newState);                           
                            
                        } else if (newState.CanMoveForward) {

                            $scope.setState(newState);

                        } else {
                            $scope.webExceptionHandlingService.displayWarningNotification("To go forward, please complete the current step");
                        }
                    }
                }).catch(angular.noop);
        };

        $scope.execBack = function () {
            if ($scope.executeOnly) {
                return;
            }

            executionService.resetCurrentWorkflowState(portletId);
            executionService.resetCurrentInboxWorkflowState(portletId);

            if (stateService.canGoBack($scope)) {
                stateService.goBack($scope);
            } else {
                var data = {};
                data.JobIdentity = $scope.JobIdentity;
                executionService.pauseJob(data);
                workflowThemeService.setColor("#F2F2F2");
                stateService.clear($scope);
            }
        };

        $scope.back = function () {
            if(stateService.inItemCreationSubworkflow($scope)) {

                var modal = $injector.get('$uibModal');
                var modalInstance = modal.open({
                    templateUrl: $.sharedAppDir + "/views/confirmDialog.html",
                    controller: 'confirmDialogController',
                    backdrop: 'static',
                    size: 'confirm-dialog-size',
                    keyboard: false,
                    resolve: {
                        dialogResult: function () {
                            return function (result) 
                                {
                                    if(result) 
                                        $scope.execBack();                                    
                                };
                        }
                    }
                });
            } else {
                $scope.execBack();
            }
        };

        $scope.exit = function () {
            var data = {};
            data.JobIdentity = $scope.JobIdentity;

            executionService.getParentJob($scope.JobIdentity)
                .then(function (result) {
                    data.ParentJobIdentity = result.data.JobIdentity;
                })
                .then(function (){
                    executionService.cancelJob(data)
                        .then(function () {
                            executionService.resetCurrentWorkflowState(portletId);
                            workflowThemeService.setColor("#F2F2F2");

                            if (appConfig.APPLICATION_TYPE === 'WebClient') {
                                stateService.clear($scope);
                                $location.path("/");
                            } else {
                                executionService.resetCurrentInboxWorkflowState(portletId);
                                stateService.goBack($scope);
                            }
                        })
                }).catch(angular.noop);
        };

        $scope.setState = function (state) {
            $scope.model.activeStep = state.ActiveStep;
            $scope.model.activeStep.Rows = userStepDataService.pickFocusedControl($scope.model.activeStep.Rows);

            // if focus not set
            var stepHasFocus=userStepDataService.userStepHasFocus($scope.model.activeStep.Rows);

            angular.forEach($scope.model.activeStep.Exits, function (exit) {
                exit.FocusOnMe = false;
            });

            if (!stepHasFocus && $scope.model.activeStep.Exits.length > 0) {
                var exitIndex = $scope.findUserStepExitIndex();

                if (exitIndex !== undefined) {
                    $scope.model.activeStep.Exits[exitIndex].FocusOnMe = true;
                }
            }

            $scope.model.canCancel = state.CanCancel;

            angular.forEach(state.NewHistorySteps,
                function (step) {
                    $scope.model.history.push(step);
                });

            angular.forEach(state.NewPinnedSteps,
                function (incomingStep) {
                    angular.forEach($scope.model.pinnedSteps, function (pinnedStep) {
                        if (pinnedStep.ActualStepId == incomingStep.ActualStepId) {
                            var index = $scope.model.pinnedSteps[pinnedStep];
                            $scope.model.pinnedSteps.splice(index, 1);
                        }
                    });
                    if ($scope.expandedPinnedStep && incomingStep.ActualStepId == $scope.expandedPinnedStep.ActualStepId) {
                        $scope.updateExpandedStep(incomingStep)
                    }
                    $scope.model.pinnedSteps.push(incomingStep);
                });

            $scope.model.history = $scope.model.history.filter(function (historyStep) {
                return state.RemovedHistoryStepIds.indexOf(historyStep.StepId) === -1;
            });

            $scope.model.pinnedSteps = $scope.model.pinnedSteps.filter(function (pinnedStep) {
                return state.RemovedPinnedStepIds.indexOf(pinnedStep.StepId) === -1;
            });

            $scope.model.oldestReachableHistoryStepId = state.OldestReachableHistoryStepId;
            $scope.executionWidth = $scope.getPreferedWidthInner($scope.model.activeStep);
        }

        $scope.showPopup = function (text, checkTitle) {
            var dots = "...";
            if (text.length > 80)
                text = text.substring(0, 80) + dots;
            if (checkTitle.length > 60)
                checkTitle = checkTitle.substring(0, 60) + dots;

            $scope.checkpointNotification.show(
                {
                    title: checkTitle,
                    message: text
                }, "info");
        };

        $scope.getClassName = function (m) {
            return m.CssClass;
        };

        $scope.getPreferedWidthInner = function (userStep) {
            var width = 40;
            if (!userStep)
                return width;

            angular.forEach(userStep.Rows, function (row) {
                if (row.Controls.length > 2) {
                    width = 70;
                }
                else {
                    angular.forEach(row.Controls, function (c) {
                        if (c.Type === "grid" || c.Type === "calendar") {
                            width = window.innerWidth > 768 ? 70 : 100;
                        }
                    });
                }
            });
            return width;
        };

        $scope.getPreferedWidthOuter = function (userStep) {
            $scope.preferedWidth = $scope.getPreferedWidthInner(userStep);
            return $scope.preferedWidth;
        };

        $scope.calculateMinPinnedHeight = function () {
            var activeIndex = historyService.findIndex($scope.model.pinnedSteps, function (s) { return s.active });
            return 120 * (activeIndex + 1); // Can we make this nicer?
        };

        $scope.savePinnedColumnWidth = function (width) {
            $cookies.put('pinnedStepWidth', width);
        };

        $scope.getPinnedColumnWidth = function () {
            return $cookies.get('pinnedStepWidth') || '40%';
        };

        $scope.updateExpandedStep = function (step) {
            step.active = true;
            $scope.expandedPinnedStep = step;
        };

        $scope.toggleStep = function (step) {
            if (step.active) {
                step.active = false;
            } else {
                angular.forEach($scope.model.pinnedSteps,
                    function (pinnedStep) {
                        pinnedStep.active = false;
                    });

                step.active = true;
            }

            var index = historyService.findIndex($scope.model.pinnedSteps, function (ps) { return ps.active });
            if (index > -1)
                $scope.expandedPinnedStep = $scope.model.pinnedSteps[index];
            else
                $scope.expandedPinnedStep = null;
        };

        $scope.init = function (title, icon) {
            $scope.workflowIcon = icon;
            $scope.workflowTitle = title;
        };

        $scope.preInitialize = function () {

            $scope.translationFlowClientService = translationFlowClientService;
            $scope.featuresService = featuresService;

            var translationsEmpty = $scope.translationFlowClientService.areTranslationsEmpty();
            var featuresEmpty = $scope.featuresService.areFeaturesEmpty();

            if (translationsEmpty || featuresEmpty) {
                var promises = [];
                promises.push($scope.translationFlowClientService.getAll(sharedSessionService.getLanguageCode()));
                promises.push($scope.featuresService.getAllFeatureFlags());
                $q.all(promises)
                    .then(function () {
                        $scope.initialize();
                    }).catch(angular.noop);
            }
            else {
                $scope.initialize();
            }
        }

        $scope.initialize = function () {
            $scope.themeColor = workflowThemeService.getColor();

            $scope.translationFlowClientService = translationFlowClientService;
            $scope.webExceptionHandlingService = webExceptionHandlingService;
            $scope.workflowThemeService = workflowThemeService;
            $scope.imageService = imageService;
            $scope.historyService = historyService;

            $scope.model = {};
            $scope.model.pinnedSteps = [];
            $scope.expandedPinnedStep = null;
            $scope.model.activeStep = null;
            $scope.model.history = [];
            $scope.historyListExpanded = false;

            $scope.checkpointNotificationOptions = {
                templates: [
                    {
                        type: "info",
                        template: $("#checkpointTemplate").html()
                    }
                ],
                show: $scope.webExceptionHandlingService.onShow
            };

            var stateParams = stateService.getStateParams($scope);
            if (stateParams.portletId)
                portletId = stateParams.portletId;

            if (stateParams.executeOnly || sharedSessionService.isPublicSession()) {
                $scope.executeOnly = true;
            }

            executionService.resume(stateParams.executionIdentity)
                .then(function (result) {
                    $scope.JobIdentity = result.data.JobIdentity;
                    if (result.data.IsCompleted) {
                        $scope.handleIsCompleted(result.data);
                    } else {
                        $scope.setState(result.data);
                    }
				}).catch(function (ex) {
		            console.log('IFrame exception' + ex);
                });
           
            $scope.show = true;
        };

        $scope.findUserStepExitIndex = function () {
            var defaultExit = undefined;

            if ($scope.model.activeStep.Exits.length === 1) {
                defaultExit = 0;
            }

            for (var i = 0; i < $scope.model.activeStep.Exits.length; i++) {
                if ($scope.model.activeStep.Exits[i].IsDefault === true) {
                    defaultExit = $scope.model.activeStep.Exits[i].ExitIndex;
                    break;
                }
            }
            return defaultExit;
        }
        
        $scope.$on(appConfig.Events.ENTER_KEY_PRESSED, function () {
            if (userStepDataService.validateControls($scope.model.activeStep.Rows) && !$scope.sendingData ) {
                var exitIndex = $scope.findUserStepExitIndex();
                $scope.moveNext(exitIndex);
            }
        });

        $scope.$on("errorHidden", function (event, args) {
            if (args !== "WorkflowStepInvalid")
                return;

            stateService.goBackToIndex($scope, 0);
        });

        keyboardService.bind('ctrl+enter', function () {
            if (userStepDataService.validateControls($scope.model.activeStep.Rows) && $scope.model.activeStep.Exits.length > 0 && !$scope.sendingData) {
                var exitIndex = $scope.findUserStepExitIndex();
                $scope.moveNext(exitIndex);
            }
        });

        keyboardService.bind('tab+enter', function () {
            if (userStepDataService.validateControls($scope.model.activeStep.Rows) && $scope.model.activeStep.Exits.length > 0) {
                var exitIndex = $scope.findUserStepExitIndex();
                $scope.moveNext(exitIndex);
            }
        });

        keyboardService.bind('esc', function () {
            $scope.back();
        });

        keyboardService.bind('ctrl+backspace', function () {
            $scope.back();
        });

        keyboardService.bind('ctrl+q', function () {
            $scope.exit();
        }); 

        keyboardService.bind('backspace', function () {
        }, {'propagate': true});
        
        $scope.$on("$destroy", function () {
            keyboardService.unbind('ctrl+enter');
            keyboardService.unbind('tab+enter');
            keyboardService.unbind('esc');
            keyboardService.unbind('ctrl+q');
            keyboardService.unbind('ctrl+backspace');
            keyboardService.unbind('backspace');
        });

        $scope.preInitialize();
    }
]);