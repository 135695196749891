app.controller("homeController", [
   "$scope", "$rootScope", "stateService", "homeStateService", "$uibModal", "workflowService", "executionService", "authenticationService",
    "sharedSessionService", "workflowThemeService", "imageService", "progressService", "$cookies", "$controller", 'keyboardService', "localizationService",

   function ($scope, $rootScope, stateService, homeStateService, $uibModal, workflowService, executionService, authenticationService,
       sharedSessionService, workflowThemeService, imageService, progressService, $cookies, $controller, keyboardService, localizationService) {

       $controller('BaseHomeController', { $scope: $scope });

       $scope.redirectToExecutionView = function (identifier) {
           
           if (identifier) {
               executionService.start(identifier, false).then(function (result) {
                    
                   var workflowHeader = result.data.WorkflowHeader;
                   executionService.setCurrentWorkflowState(identifier, result.data.WorkflowHeader);
                   workflowThemeService.setColor(workflowHeader.ThemeColor);

                   stateService.go($scope, 'execution', { executionIdentity: result.data.JobIdentity, isHandover: false, name: workflowHeader.Title, workflowTitle: workflowHeader.Title, workflowIcon: workflowHeader.IconIdentifier });
               }).catch(angular.noop);
           }
       };

  
       $scope.getMenuConfiguration = function () {

           $scope.activeLoader = true;
           $scope.workflowService.getMenuConfiguration().then(function (result) {

               $scope.model = angular.fromJson(result.data);
               $scope.buildTabs();
               $scope.restoreLastInbox();
               $scope.activeLoader = false;
               $scope.setActiveTab(homeStateService.getActiveTab());
           }).catch(angular.noop);
       };
       //angular and uib-tabset requeires 'active' as value, not a function
       $scope.buildTabs = function () {
           angular.forEach($scope.model, function (menu) {

               var newTab = {
                   Title: menu.Title,
                   Categories: menu.Categories,
                   RootItems: menu.RootItems,
                   Size: menu.Size,
                   Id: menu.Id
               };
               $scope.tabs.push(newTab);
           });
       };

       $scope.restoreLastInbox = function () {
           var lastInboxId = homeStateService.getLastInboxId();
           if (lastInboxId) {
               angular.forEach($scope.model, function (menu) {
                   angular.forEach(menu.RootItems, function (item) {
                       if (item.Type == 'inbox' && item.Inbox.Identifier == lastInboxId)
                           $scope.showInbox(item.Inbox.WebExecutionInfos, item.Inbox.IconString, item.Inbox.Title, lastInboxId);
                   });
               });
           }
       };     

       $scope.setActiveTab = function (tabId) {
           var matchedTab = false;

           if (tabId != null) {
               for (var i = 0; i < $scope.tabs.length; i++) {
                   if ($scope.tabs[i].Id === tabId) {
                       $scope.CurrentTab = $scope.tabs[i];
                       $scope.activeTabIndex = i;
                       matchedTab = true;
                       break;
                   }
               }
           }
           if (!matchedTab) {
               if ($scope.tabs.length > 0) {
                   $scope.CurrentTab = $scope.tabs[0];
                   tabId = $scope.tabs[0].Id;
               }
           }
           if (tabId) {
               homeStateService.saveActiveTab(tabId);
               $scope.activeTab = tabId;
           }
       };

       $scope.showInbox = function (myData, iconIdentifier, inboxTitle, inboxIdentifier) {

           homeStateService.saveLastInboxId(inboxIdentifier);

           var modalInstance = $uibModal.open({
               templateUrl: $.sharedAppDir + "/views/inbox.html",
               controller: 'inboxController',
               backdrop: 'static',
               keyboard: true,
               resolve: {
                   data: function () {
                       return myData;
                   },
                   iconIdentifier: function () {
                       return iconIdentifier;
                   },
                   inboxTitle: function () {
                       return inboxTitle;
                   }
               }
           });
           modalInstance.result.then(function (inboxData) {

               if (inboxData) {
                   if (inboxData.handoverIdentity) {
                       executionService.takeJob(inboxData.handoverIdentity).then(function (result) {
                           var webStart = angular.fromJson(result.data);
                            executionService.setCurrentWorkflowState(inboxData.handoverIdentity, {
                                      executionIdentity: result.data.JobIdentity,
                                      isHandover: true,
                                      name: inboxData.name,
                                      workflowTitle: inboxData.name,
                                      workflowIcon: inboxData.icon}, true);
                           stateService.go($scope, 'execution', { executionIdentity: webStart.JobIdentity, isHandover: true, name: inboxData.name, workflowTitle: inboxData.name, workflowIcon: inboxData.icon });
                       }).catch(angular.noop);

                   }
               }
           }, function () {
               //handle popup dismiss on cancel
           }).catch(angular.noop);
       };

       $scope.selectPreviousTab = function () {
           $scope.navigateThroughTabs('prev');
       };
       $scope.selectNextTab = function () {
           $scope.navigateThroughTabs('next');
       };

       $scope.navigateThroughTabs = function (direction) {

           var noOfTabs = $scope.tabs.length;
           var newActiveIndex;

           if (noOfTabs > 1) {
               for (var i = 0; i < noOfTabs; i++) {
                   if (direction === 'next') {
                       if (i === (noOfTabs - 1))
                           newActiveIndex = 0;
                       else
                           newActiveIndex = i + 1;
                   }
                   else {
                       if (i === 0)
                           newActiveIndex = noOfTabs - 1;
                       else
                           newActiveIndex = i - 1;
                   }

                   if (i === $scope.activeTabIndex) {
                       $scope.setActiveTab($scope.tabs[newActiveIndex].Id);                                        
                       break;
                   }
               }
           }
       };

       $scope.selectNextElement = function () {

           if ($scope.HighlightedElement != null) {        

               var noOfItems=$scope.CurrentTab.RootItems.length;
               
               angular.forEach($scope.CurrentTab.Categories, function (category) {         
                   noOfItems+=category.Items.length;
               });

               for (var i = $scope.HighlightedElement; i < noOfItems; i++) {                  
                       if (i + 1 < noOfItems) {
                           $scope.HighlightedElement = i + 1;
                           break;
                       }                   
               }
           }
           else {
               $scope.HighlightedElement = 0;
           }
       };

       $scope.selectPreviousElement = function () {

           if ($scope.HighlightedElement != null) {

               var noOfItems = $scope.CurrentTab.RootItems.length;

               angular.forEach($scope.CurrentTab.Categories, function (category) {
                   noOfItems += category.Items.length;
               });

               for (var i = $scope.HighlightedElement; i >= 0 ; i--) {
                   if (i - 1 >= 0) {
                       $scope.HighlightedElement = i - 1;
                       break;
                   }
               }
           }
           else {
               $scope.HighlightedElement = 0;
           }
       };
    


        $rootScope.$on('ReloadMenus', function (event, data) {
            $scope.tabs = [];
            $scope.getMenuConfiguration();
        });

        keyboardService.bind('ctrl+right', function () {
            if (homeStateService.hasInboxId()) return;
            $scope.HighlightedElement = null;
            $scope.selectNextTab();
        });
        keyboardService.bind('right', function () {
            if (homeStateService.hasInboxId()) return;
            $scope.selectNextElement();
        });
        keyboardService.bind('left', function () {
            if (homeStateService.hasInboxId()) return;
            $scope.selectPreviousElement();
        });
        keyboardService.bind('ctrl+left', function () {
            if (homeStateService.hasInboxId()) return;
            $scope.HighlightedElement = null;
            $scope.selectPreviousTab();
        });

        keyboardService.bind('enter', function () {  
            if (homeStateService.hasInboxId()) return;
            var currentWorkflow = null;
            var currentInbox = null;      
            var allRunableItems = $scope.CurrentTab.RootItems;

            angular.forEach($scope.CurrentTab.Categories, function (category) {
                allRunableItems = allRunableItems.concat(category.Items);
            });

            if ($scope.CurrentTab)
            {
                for (var i = 0; i < allRunableItems.length; i++) {
                    if (allRunableItems[i].TrackIndex == $scope.HighlightedElement) {
                        if (allRunableItems[i].Type == 'inbox') {
                            currentInbox = allRunableItems[i];
                        } else {
                            currentWorkflow = allRunableItems[i];
                        }                   
                        break;
                    }
                }         
            }
            if (currentWorkflow)
                $scope.redirectToExecutionView(currentWorkflow.WorkflowHeader.Identity,
                    null,
                    currentWorkflow.WorkflowHeader.ThemeColor,
                    currentWorkflow.WorkflowHeader.Title,
                    currentWorkflow.WorkflowHeader.IconIdentifier);
            
            if (currentInbox) {
                $scope.showInbox(currentInbox.Inbox.WebExecutionInfos, currentInbox.Inbox.IconString, currentInbox.Inbox.Title, currentInbox.Inbox.Identifier);
            }
        });

       $scope.$on("$destroy", function (event) {
           keyboardService.unbind('ctrl+left');
           keyboardService.unbind('ctrl+right');
           keyboardService.unbind('enter');
           keyboardService.unbind('left');
           keyboardService.unbind('right');
       });

       $scope.isHighlighted = function (index) {
           return $scope.HighlightedElement == index;
       }

       $scope.initialize = function () {
           $scope.model = [];
           $scope.tabs = [];
           $scope.activeLoader = false;
           $scope.HighlightedElement = null;
           $scope.CurrentTab = null;

           $scope.workflowService = workflowService;
           $scope.executionService = executionService;
           $scope.workflowThemeService = workflowThemeService;
           $scope.homeStateService = homeStateService;
           $scope.imageService = imageService;

           $scope.getMenuConfiguration();
           localizationService.setLocalization(sharedSessionService.getLanguageCode(), sharedSessionService.getCulture());
       };

       $scope.initialize();
   }
]);