app.controller("takeBackSittingController",
    [
        "$scope", "$rootScope", "$uibModalInstance", "authenticationService", "$route",
        "stateService", "translationFlowClientService", "errorMessage", "routeReload",
        function($scope,
            $rootScope,
            $uibModalInstance,
            authenticationService,
            $route,
            stateService,
            translationFlowClientService,
            errorMessage,
            routeReload) {

            $scope.initialize = function() {
                $scope.translationFlowClientService = translationFlowClientService;
                $scope.authenticationService = authenticationService;
                $scope.errorMessage = errorMessage;
            };

            $scope.initialize();

            $scope.logout = function() {
                $uibModalInstance.close();
                $rootScope.isThiefModalOpened = false;
                authenticationService.logout();
            };

            $scope.takeBackSitting = function() {
                authenticationService.takeBackSitting().then(function() {
                    $rootScope.isThiefModalOpened = false;
                    if (routeReload) {
                        $uibModalInstance.close();
                        $route.reload();
                    } else {
                        $uibModalInstance.close({ reloadMenu: true });
                        stateService.reload($scope);
                    }
                }).catch(angular.noop);
            };
        }
    ]);