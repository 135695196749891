// NOTE:
// forEach and find work here in IE 9 because of polyfills buried somewhere in jquery or kendo or angular,
// so no need to change these :) 
// however, arrow functions do not work, so REMEMBER always test in IE 9 simulator after messing around here (or elsewhere in the webclient.)

app.controller("gridController", [
    "$scope",
    "$rootScope",
    "gridService",
    "featuresService",
    "$timeout",
    "coloringService",
    "gridColumnSizingService",
    "sharedSessionService",
    function($scope, $rootScope, gridService, featuresService, $timeout, coloringService, gridColumnSizingService, sharedSessionService) {
        $scope.displayLoadingBar = false;
        $scope.gridOptions = {};
        $scope.gridId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        //used by calendar
        $scope.hasRows = false;
        $scope.columns = [];
        // end
        var isUpdating = false;
        var tabbed = false;
        var enter = false;
        var clicked = undefined;

        var eventsInitilized = false;
        var lastFocusedRowIndex = -1;
        var lastFocusedColumnIndex = -1;
        var noneFormatStringValue = "nfsv";
        var typefuncs = [
            { name: "valueList", func: setValueList },
            { name: "checkbox", func: setCheckBox },
            { name: "numeric", func: setNumeric },
            { name: "computed", func: setNumeric }
        ];

        //used by calendar
        $scope.createColumns = function(columns) {
            var toReturn = [{ field: "Id", hidden: true, width: 0 }];

            if ($scope.isGridCalendar) {
                toReturn.push({ field: "CalendarOrderId", hidden: true, width: 0 });
            }

            if ($scope.c.AllowDelete) {
                toReturn.push(addDeleteButton());
            }

            if (featuresService.hasFeature("GridRowSelection") && $scope.c.IsSelectable && !$scope.isGridCalendar) {
                toReturn.push(addSelectButton($scope.c.MultiSelect));
            }

            columns.forEach(function(col, ix) {
                toReturn.push(addGridColumn(col, ix));
            });
            //grid has not been assigned Id yet, so this shit actually works.
            // ok if we have more than one grid , because length of grid is always the same :) 
            var grid = document.getElementById("{{gridId}}");
            // but in case someone calls this after init lets play it on the safe side:
            if (!grid) {
                grid = document.getElementById($scope.gridId);
            }
            if (grid) {
                gridColumnSizingService.reviseColumnWidths(toReturn, grid.clientWidth);
            }
            return toReturn;
        };
        // end

        $scope.addRow = function() {
            $scope.grid.addRow();
        };

        $scope.isRowIdStateAdded = function(id) {
            return (
                $scope.c.Rows &&
                $scope.c.Rows.some(function(itm) {
                    return itm.Id === id && itm.State === "Added";
                })
            );
        };

        $scope.updateRow = function(dataItem) {
            if (isUpdating) return;

            isUpdating = true;
            $rootScope.IsGridUpdating = true;

            var toSend = prepareToSend();
            toSend.Row = {
                Id: dataItem.Id,
                Cells: []
            };

            var idx = findRowIndexById(dataItem["Id"]);

            var i = 0;
            for (var property in dataItem) {
                if (dataItem.hasOwnProperty(property) && property !== "Id") {
                    for (var z = 0; z < $scope.c.Columns.length; z++) {
                        if ($scope.c.Columns[z].Code === property) {
                            // if it is a "formatted stringed" numeric, we have to manually update it too
                            // because that is the value we will actually be sending to the server for update.
                            // check !== undefined because if value is empty it will be "null", but if none existing in "dateItem" it will be undefined instead
                            if (dataItem[property + noneFormatStringValue] !== undefined) {
                                $scope.c.Rows[idx].Cells[i].NoneFormatValue = dataItem[property+noneFormatStringValue];
                            }
                            
                            // but we always update the usual value.
                            $scope.c.Rows[idx].Cells[i].Value = dataItem[property];
                            i++;
                            break;
                        }
                    }
                }
            }

            for (var index in $scope.c.Rows[idx].Cells) {
                var cell = $scope.c.Rows[idx].Cells[index];
                var value = cell.HasFormatString ? cell.NoneFormatValue : cell.Value;
                toSend.Row.Cells.push({
                    __type: cell["__type"],
                    Value: value
                });
            }

            var grid = $("#" + $scope.gridId);

            gridService
                .update(toSend)
                .then(function(result) {
                    var item = $scope.gridOptions.dataSource.at(idx);
                    var row = grid.find("tbody").find("tr[data-uid='" + item.uid + "']");
                    var startIndex = calculateColumnIndex();

                    $scope.c.Rows[idx] = result.data;

                    for (var i = 0; i < $scope.c.Rows[idx].Cells.length; i++) {
                        var cell = $scope.c.Rows[idx].Cells[i];
                        var column = $scope.columns[i + startIndex];

                        // shall be == null because of null & undefined check.
                        // We need to do this in a bad way because we do not want to re-read the whole datasource
                        // which makes the whole table to be ce-created which is even worse.
                        if (column.type === "valueList" && cell.Value == null) {
                            // Cascade update the nulled cell
                            row
                                .children()
                                .eq(startIndex + i) // the childrens are offset by startIndex
                                .text("");
                        }

                        // because of two different "type" of bindings for numeric we have to handle this differently
                        // if you are updating a cell with a formatdisplay setting.
                        if (column.type === "numeric" && cell.HasFormatString) {
                            item["code" + i + noneFormatStringValue] = cell.NoneFormatValue;
                            delete item.dirtyFields["code" + i + noneFormatStringValue];
                        }
                        item["code" + i] = gridService.transformValue(
                            column,
                            cell.Value
                        );
                        delete item.dirtyFields["code" + i];
                    }

                    if (Object.keys(item.dirtyFields).length === 0) {
                        item.dirty = false;
                    }

                    coloringService.colorRowAndCells($scope.c.Rows[idx], startIndex, row);

                    if (enter) focusNextField(lastFocusedRowIndex, lastFocusedColumnIndex);

                    if ($scope.isGridCalendar) $scope.refreshCalendar(false);
                })
                .then(function() {
                    $("#" + $scope.gridId)
                        .find(".k-loading-mask")
                        .remove();
                    $scope.displayLoadingBar = false;

                    if (
                        clicked &&
                        !tabbed &&
                        !enter &&
                        !(clicked.rowIndex === lastFocusedRowIndex && lastFocusedColumnIndex === clicked.columnIndex)
                    ) {
                        var $row = $("#" + $scope.gridId + " tr").eq(clicked.rowIndex);
                        var $cell = $row.find("td:eq(" + clicked.columnIndex + ")");

                        if (isCellEditable($scope.gridOptions.dataSource.at($row.index()).Id, clicked.columnIndex - 1)) {
                            grid.data("kendoGrid").editCell($cell);
                        }
                    }
                    tabbed = false;
                    enter = false;
                    isUpdating = false;
                    $rootScope.IsGridUpdating = false;
                });
        };

        function initialize() {
            $scope.columns = $scope.createColumns($scope.c.Columns);

            $scope.gridOptions = {
                dataSource: new kendo.data.DataSource({
                    autoSync: true,
                    transport: {
                        read: function(options) {
                            options.success(createRows($scope.c.Rows));
                        },
                        create: function(options) {
                            var toSend = prepareToSend();

                            //var row = undefined;
                            if ($scope.isGridCalendar) {
                                appendRow(
                                    function() {
                                        return gridService.addCalendarRow(toSend);
                                    },
                                    $scope.isGridCalendar,
                                    options
                                );
                            } else {
                                appendRow(
                                    function() {
                                        return gridService.add(toSend);
                                    },
                                    $scope.isGridCalendar,
                                    options
                                );
                            }
                        },
                        update: function(options) {
                            $scope.updateRow(options.data);
                        },

                        destroy: function(options) {
                            $scope.displayLoadingBar = false;

                            var toSend = prepareToSend();
                            toSend.Id = options.data.Id;

                            gridService
                                .delete(toSend)
                                .then(function() {
                                    options.success(options.data);

                                    for (var i = 0; i < $scope.c.Rows.length; i++) {
                                        if ($scope.c.Rows[i].Id === toSend.Id) {
                                            $scope.c.Rows.splice(i, 1);
                                            break;
                                        }
                                    }
                                    $scope.displayLoadingBar = false;
                                    
                                    // when deleting an item, we also need to check if we need to go back in pages
                                    // since we are not doing a full actual "refresh"
                                    var grid = $("#" + $scope.gridId).data("kendoGrid");
                                    if (grid.dataSource.pageSize()) {
                                        var rest = $scope.c.Rows.length % grid.dataSource.pageSize();
                                        if (rest === 0 && grid.dataSource.totalPages() > 0) {
                                            grid.pager.page(grid.dataSource.totalPages()); // go back if it was the last item in grid pages
                                        }
                                    }

                                    if ($scope.isGridCalendar) $scope.refreshCalendar(true);
                                })
                                .catch(function(ex) {
                                    console.log(ex);
                                });
                        }
                    },
                    schema: {
                        model: {
                            id: "Id",
                            fields: createFields($scope.c.Columns),
                        }
                    },
                    pageSize: getGridPageSize()
                }),

                save: function() {
                    this.current(this.current());
                },

                dataBound: function(e) {
                    var grid = e.sender;
                    var dataItems = [];
                    var itemsToSelect = $scope.c.SelectedRowGuids;

                    initEventsOnGrid(grid);

                    grid.items().each(function(idx, row) {
                        var dataItem = grid.dataItem(row);
                        dataItems.push(dataItem);
                        if ($scope.c.SelectedRowGuids.indexOf(dataItem["Id"]) > -1) {
                            itemsToSelect.push(row);
                        }
                    });
                    grid.select(itemsToSelect);

                    $timeout(function() {
                        coloringService.colorDataItems(
                            $scope.c.Rows,
                            e.sender.tbody,
                            $scope.gridOptions.dataSource,
                            calculateColumnIndex()
                        );
                    });
                },
                resizable: true,
                navigatable: true,
                scrollable: true,
                pageable: {
                    pageSizes: [10, 25, 50, "all"]
                },
                sortable: {
                    mode: "single",
                    allowUnsort: true
                },
                change: onChange,
                persistSelection: true,
                edit: function(e) {
                    clicked = undefined;
                    var grid = this;
                    if (isCellEditable(e.model.Id, e.container.index() - 1)) {
                        var input = e.container.find("span.k-dropdown:first input");
                        lastFocusedColumnIndex = e.container.index();

                        //Get the current tr
                        var $row = $("#" + $scope.gridId + " tr[data-uid='" + e.model.uid + "']")[0];
                        //Save the index of the current focused row
                        lastFocusedRowIndex = $("#" + $scope.gridId + " tr").index($row);

                        var el = input.data("kendoDropDownList");
                        if (el) {
                            el.open();
                        }
                    } else {
                        grid.closeCell();
                    }
                },
                editable: {
                    createAt: "bottom"
                },
                columns: $scope.columns
            };
        }

        function addDeleteButton() {
            return {
                command: [{
                    name: "Delete",
                    template: "<div class='k-button k-button-icontext k-grid-delete webGridButton'><span class='k-icon k-i-close'></span></div>",
                    click: function(e) {
                        e.preventDefault();
                        $("#" + $scope.gridId)
                            .data("kendoGrid")
                            .dataSource.remove(this.dataItem($(e.target).closest("tr")));
                    }
                } ],
                width: 38,
                minResizableWidth: 38
            };
        }
        
        var gridBaseStorageName = "grid:pagsize:";
        function getGridPageSize() {
            var size = window.localStorage.getItem(gridBaseStorageName + $scope.c.Identity);
            
            // If the stored value is not a number AKA "all" we return undefined
            if (size && isNaN(size)) 
                return undefined; // we return undefined because it is the way that "all" sets its value in the end.
            
            // otherwise we just convert it to a number and return it.
            if (size)
                return Number(size);
            
            var defaultValue = Number($.defaultGridPageSize);

            if (isNaN(defaultValue))
                return undefined; // if not a number then we show all
            
            return defaultValue > 0 ? defaultValue : 10; // should be a number, but verify its bigger than 0
        }
        
        // save the changed size with the actual "identity" of the grid.
        function saveGridPageSize(size) {
            window.localStorage.setItem(gridBaseStorageName + $scope.c.Identity, size);
        }

        function addGridColumn(column, ix) {
            var toAdd = {
                field: column.Code,
                hidden: !column.IsVisible,
                type: column.Type,
                title: column.Title ? column.Title : "&nbsp",
            };

            reactOnColumnMode(toAdd, column.SizeMode, column.Width, ix);
            reactOnType(column, toAdd, ix);
            toAdd.attributes = { 'class': getColumnClass(column.Type, column.SizeMode) }

            return toAdd;
        }

        function addSelectButton(isMultiSelect) {
            var retval = { 
                width: 36,
                minResizableWidth: 36,
                attributes: {
                    "class": "nv-checkbox-grid"
                }
            };
            if (isMultiSelect) {
                retval.selectable = "multiple,rows";
            } else {
                retval.selectable = true;
                retval.headerTemplate = " ";
            }
            return retval;
        }

        function appendRow(func, isCalendar, options) {
            var row = undefined;
            func()
                .then(function(r) {
                    $scope.c.Rows.push(r.data);
                    row = createRow($scope.grid.dataSource.data().length + 1, r.data);
                    options.success(row);
                    if (isCalendar) {
                        $scope.hasRows = $scope.gridOptions.dataSource.total() > 0;
                        $scope.refreshCalendar(false);
                    }
                })
                .then(function() {
                    $scope.grid.dataSource.read().then(function() {
                        $scope.displayLoadingBar = false;
                        pageToRow();
                        scrollToSelectedRow(findRowIndexById(row.Id));
                    });
                })
                .catch(function(ex) {
                    console.log(ex);
                });
        }

        function calculateColumnIndex(colIndex, inc) {
            // IE Does not do defaults, therefore these sucky lines :/
            // default on 1 to skip hidden id column
            colIndex = colIndex === undefined ? 1 : colIndex;
            inc = inc === undefined ? true : inc;

            var valueToAdd = inc ? 1 : -1;
            if ($scope.isGridCalendar) colIndex += valueToAdd;
            if ($scope.c.IsSelectable) colIndex += valueToAdd;
            if ($scope.c.AllowDelete) colIndex += valueToAdd;

            return colIndex;
        }

        function createFields(cols) {
            var toReturn = { Id: { editable: false } };

            if ($scope.isGridCalendar) {
                toReturn.CalendarOrderId = { editable: false };
            }

            cols.forEach(function(x) {
                toReturn[x.Code] = {};
                if (x.Type === "checkbox") {
                    toReturn[x.Code].type = "boolean";
                    toReturn[x.Code].editable = false;
                } else {
                    toReturn[x.Code].editable = x.IsEditable || x.EditableForNew;
                }
            });

            return toReturn;
        }

        function createRow(index, row) {
            var element = { Id: row.Id };
            var colIndex = calculateColumnIndex();

            if ($scope.isGridCalendar) {
                element.CalendarOrderId = index;
            }
            for (var i = 0; i < row.Cells.length; i++) {
                var cell = row.Cells[i];
                var column = $scope.columns[colIndex];

                if (column && column.type === "numeric" && cell.HasFormatString)
                    element[column.field + noneFormatStringValue] = cell.NoneFormatValue;

                element[column.field] = gridService.transformValue(
                    column,
                    cell.Value
                );
                colIndex += 1;
            }
            return element;
        }

        function createRows(rows) {
            var toReturn = [];
            rows.forEach(function(row, ix) {
                toReturn.push(createRow(ix, row));
            });
            return toReturn;
        }

        function findRowIndexById(id) {
            for (var k = 0; k < $scope.c.Rows.length; k++) {
                if ($scope.c.Rows[k].Id === id) {
                    return k;
                }
            }
        }

        function focusNextField(currentRowIndex, focusedCellIndex) {
            if (lastFocusedRowIndex > -1) {
                var $rows = $("#" + $scope.gridId + " tr");
                var $row = $rows.eq(currentRowIndex);
                var $grid = $("#" + $scope.gridId).data("kendoGrid");
                var cols = $row.find("td").length;

                var rowAndCellToFocus = getNextFocusableRowAndCellId($rows.length, currentRowIndex, cols, focusedCellIndex);
                $row = $rows.eq(rowAndCellToFocus.rowIndexToFocus);

                var $cell = $row.find("td:eq(" + rowAndCellToFocus.cellIndexToFocus + ")");
                var isNextCellEditable = isCellEditable(
                    $scope.gridOptions.dataSource.at($row.index()).Id,
                    rowAndCellToFocus.cellIndexToFocus - 1
                );

                if (isNextCellEditable) {
                    if ($cell.index() === rowAndCellToFocus.cellIndexToFocus && $row.index() === 0) {
                        return; // no more focus field found
                    }
                    $grid.editCell($cell);
                    return; // focus field found & selected
                } else {
                    //Recursive method call, do this again untill we find next editable control
                    focusNextField(rowAndCellToFocus.rowIndexToFocus, rowAndCellToFocus.cellIndexToFocus);
                }
            }
        }

        function getValueListTemplate(colIndex) {
            return function(dataItem) {
                var toReturn = "";
                angular.forEach($scope.c.Rows, function(x) {
                    if (x.Id === dataItem.Id) {
                        var el = x.Cells[colIndex];
                        angular.forEach(el.Options, function(op) {
                            if (op.Value === el.Value) {
                                toReturn = op.Text;
                            }
                        });
                    }
                });
                return toReturn;
            };
        }

        function getNextFocusableRowAndCellId(rowCount, currentRowIndex, columnCount, focusedCellIndex) {
            var rowIndexToFocus = currentRowIndex;
            var cellIndexToFocus = focusedCellIndex;

            if (rowCount < currentRowIndex) {
                //Reset to row number one
                currentRowIndex = 0;
            }

            if (columnCount > focusedCellIndex + 1) {
                //Next column
                cellIndexToFocus++;
            } else {
                //Select the first column in next row
                cellIndexToFocus = 1;
                if (rowCount > currentRowIndex + 1) {
                    //Next row
                    rowIndexToFocus = currentRowIndex + 1;
                } else {
                    //Navigate to the first row
                    rowIndexToFocus = 1;
                }
            }

            return {
                rowIndexToFocus: rowIndexToFocus,
                cellIndexToFocus: cellIndexToFocus,
            };
        }

        function initEventsOnGrid(grid) {
            if (eventsInitilized) return;

            grid.tbody.on("click", ".k-checkbox", onClick);
            grid.tbody.on("keydown", onKeyClick);
            grid.tbody.on("mousedown", "td", onGridBodyClick);
            
            // bind event of page size change, so we can save the user latest selected value.
            var pageSizer = $(grid.pager.element).find("[data-role='dropdownlist']").data("kendoDropDownList");
            pageSizer.bind("change", function(ev) {
                saveGridPageSize(ev.sender.value());
            });

            eventsInitilized = true;
        }

        function isCellEditable(rowId, columnIndex) {
            var column = $scope.c.Columns[calculateColumnIndex(columnIndex, false)];

            return !column ?
                false :
                (column.IsEditable && column.IsVisible) ||
                (column.EditableForNew && $scope.isRowIdStateAdded(rowId) && column.IsVisible);
        }

        function onChange(arg) {
            $scope.c.SelectedRowGuids = arg.sender.selectedKeyNames();
        }

        function onClick(e) {
            if (isUpdating) return;

            var grid = $("#" + $scope.gridId).data("kendoGrid");

            if ($scope.c.IsSelectable && grid) {
                if (!$scope.c.MultiSelect) {
                    if ($(e.target).closest("tr").hasClass("k-state-selected")) {
                        setTimeout(function(e) {
                            grid.clearSelection();
                        });
                    } else {
                        grid.clearSelection();
                    }
                }
            }
        }

        function onGridBodyClick(e) {
            if (e.which === 1)
            // mouse click 1
                clicked = {
                columnIndex: e.currentTarget.cellIndex,
                rowIndex: $($(e.currentTarget).closest("tr")).index() + 1,
            };
        }

        function onKeyClick(e) {
            tabbed = e.keyCode === 9; // tab-key
            enter = e.keyCode === 13; // enter-key
        }

        function pageToRow() {
            var grid = $("#" + $scope.gridId).data("kendoGrid");
            grid.pager.page(grid.pager.totalPages());
        }

        function prepareToSend() {
            return {
                ControlIndex: $scope.cIndex,
                StepRowIndex: $scope.rowIndex,
                JobIdentity: $scope.JobIdentity,
            };
        }

        function reactOnColumnMode(col, sizeMode, width, colIndex) {
            if (col.hidden) return;
            col.isGridFit = sizeMode === "Fit";

            if (!col.minResizableWidth) // if a min size is not set yet, then we set it to 28; Make sure you cannot hide a column out of the blue.
                col.minResizableWidth = 28;

            if (!col.isGridFit) {
                col.width = width * (window.innerWidth > 600 ? 1.5 : 1);
            } else {
                col.width = calcColumnWidth(col, colIndex);
            }
        }

        function calcColumnWidth(col, colIndex) {
            var longestRowText = gridColumnSizingService.findLongestText($scope.c.Rows.slice(0, 20), colIndex);
            var rowTextLength = longestRowText.length;
            var columnHeaderLength = $scope.c.Columns[colIndex].Title ? $scope.c.Columns[colIndex].Title.length : 0;

            // DANGER - hardcoded font, used in tr & th, not possible to get before "render"/"init" has been completed.
            var font = "14px Roboto, sans-serif";

            var textWidth = gridColumnSizingService.getTextWidth(
                rowTextLength > columnHeaderLength ? longestRowText : $scope.c.Columns[colIndex].Title,
                font
            );

            var calculatedTextSize = (textWidth + 20);

            if (col.Type === "checkbox") {
                var minWidth = 44; // otherwise there will be "..." after the checkbox.
                return minWidth > calculatedTextSize ? minWidth : calculatedTextSize;
            } else {
                return calculatedTextSize;
            }
        }

        function reactOnType(type, col, ix) {
            var func = typefuncs.find(function(itm) {
                return itm.name === type.Type;
            });
            if (func) {
                func.func(type, col, ix);
            }
        }

        function scrollToSelectedRow(r) {
            var grid = $("#" + $scope.gridId).data("kendoGrid");

            if (!grid.select().prevObject[r]) return;

            var distance = grid.select().prevObject[r].offsetTop - grid.element.find("tbody").offset().top;

            grid.element.find(".k-grid-content").animate({
                    scrollTop: distance
                },
                400
            );
        }

        function setCheckBox(type, col, ix) {
            var modelstr = 'ng-model = "dataItem.' + type.Code + '" type = "checkbox"';

            if (type.IsEditable) {
                col.template = "<input " + modelstr + ' ng-change="updateRow(dataItem)"></input>';
            } else if (type.EditableForNew) {
                col.template =
                    '<input ng-disabled="!isRowIdStateAdded(dataItem.Id)" ' +
                    modelstr +
                    ' ng-change="updateRow(dataItem)"></input>';
            } else {
                col.template = "<input disabled=disabled " + modelstr + " ></input>";
            }
        }

        function convertNumber(num, locale) {
            var number = (0.1).toLocaleString(locale);
            var decimalSign = number.indexOf('.') > -1 ? '.' : ',';
            var regex = number.indexOf('.') > -1 
                ? /[^.\d]/g
                : /[^,\d]/g;
            return +num
                .replace(regex, '')
                .replace(decimalSign, '.');
        }

        function getColumnClass(type, sizeMode) {
            var classes = [];

            if (sizeMode === "Fit") {
                classes.push("grid-fit");
            }

            if (sizeMode === "Break") {
                classes.push('grid-break');
            }

            if (sizeMode === "Clip") {
                classes.push('grid-clip');
            }
            
            if (type === 'numeric') {
                classes.push('numericColumn');
            }

            if (type === 'checkbox') {
                classes.push('checkboxColumn');
            }

            return classes.join(' ');
        }

        function setNumeric(type, col, ix) {
            col.sortable = {
                compare: function(a, b) {
                    var nA = convertNumber(a[type.Code], sharedSessionService.getCulture());
                    var nB = convertNumber(b[type.Code], sharedSessionService.getCulture());
                    return nA - nB;
                }
            };

            if (type.IsEditable || type.EditableForNew) {
                col.editor = function(container, options) {
                    // if value has format string, we wanna bind the editor to change the NONE formatted string.
                    // due to binding to different "fields" we have to handle this in update row.
                    var value = options.model[options.field + noneFormatStringValue] != null 
                        ? options.field + noneFormatStringValue
                        : options.field;
                    $('<input data-bind="value:' + value + '"/>')
                        .appendTo(container)
                        .kendoNumericTextBox({
                            format: "{0:###########.############}",
                            decimals: 10,
                            min: -999999999999999, // Kendo supports 16 digits
                            max: 999999999999999,
                            spinners: false
                        });
                };
            }
        }

        function setValueList(type, col, ix) {
            col.template = getValueListTemplate(ix);
            col.editor = function(container, options) {
                var valueListItems =
                    $scope.c.Rows[findRowIndexById($scope.grid.dataItem(container.closest("tr")).Id)].Cells[
                        calculateColumnIndex(container.index() - 1, false)
                    ].Options;

                var inputList = $('<input data-bind="value:' + options.field + '"/>');

                inputList.appendTo(container).kendoDropDownList({
                    dataTextField: "Text",
                    dataValueField: "Value",
                    valuePrimitive: true,
                    filter: "contains",
                    virtual: {
                        itemHeight: 26,
                        valueMapper: function(options) {
                            // Value to INDEX mapper default on first if none found?
                            for (var i = 0; i < valueListItems.length; ++i) {
                                if (valueListItems[i].Value === options.value) {
                                    options.success([i]);
                                    return;
                                }
                            }
                            options.success([0]);
                        }
                    },
                    height: 520,
                    dataSource: new kendo.data.DataSource({
                        data: valueListItems,
                        pageSize: 80
                    })
                });
            };
        }
        initialize();
    },
]);