app.controller('checkListSubTaskController', ['$scope', 'stateService', 'executionService', 'workflowThemeService', 'translationFlowClientService', 'imageService', 'userStepDataService', function ($scope, stateService, executionService, workflowThemeService, translationFlowClientService, imageService, userStepDataService) {

    $scope.startCheckItem = function (item) {
        if (!item.IsCompleted) {

            var data = {};
            data.WebCompletedExecution = {};
            data.JobIdentity =$scope.$parent.$parent.JobIdentity;
            data.SubTaskIdentity = $scope.$parent.item.Identity;
            data.WebCompletedExecution.Controls = userStepDataService.getLimitedUserStepData($scope.model.activeStep.Rows);
            data.WebCompletedExecution.Identifier = $scope.model.activeStep.Identifier;
            data.ItemId = item.ItemId;
            
            executionService.startCheckItem(data).then(function (result) {
                stateService.go($scope, "execution", { executionIdentity: result.data.JobId, isHandover: false, name: item.Text });
            });
        }
    }

    $scope.initialize = function () {
        $scope.workflowThemeService = workflowThemeService;
        $scope.imageService = imageService;
        $scope.themeColor = workflowThemeService.getColor();
        $scope.translationFlowClientService = translationFlowClientService;
    };

    $scope.initialize();
}])