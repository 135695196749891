app.controller('binaryOptionController', ['$scope', function ($scope) {        
    $scope.onBinaryOptionClick = function (model, option) {
        if (option == model.OptionOneText) {
            model.IsOptionTwo = false;
            model.IsOptionOne = true;
        }
        else if (option == model.OptionTwoText) {
            model.IsOptionTwo = true;
            model.IsOptionOne = false;
        }
    };
    $scope.generateControlRandomKey = function () {

        var result = Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
        return result;
    };

    $scope.initialize = function () {
        $scope.controlKey = $scope.generateControlRandomKey();
    };
    $scope.initialize();

}])