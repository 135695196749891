app.controller("logoController",["$scope", "logoService", function($scope, logoService) {

    $scope.displayLogo = false;

    $scope.init = function () {
        logoService.GetLogo().then(function (result) {
            if (result && !result.failure) {
                $scope.logo = result;
                $scope.displayLogo = true;
            }
        }).catch(angular.noop);
    };
}])