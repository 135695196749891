app.controller('cameraInputController', ['$scope', '$http', 'fileService', function ($scope, $http, fileService) {
    
    var isImage = function (name) {

        var extension = name.substring(name.lastIndexOf('.')).toLowerCase();

        var validFileType = ".jpg , .jpeg , .png , .bmp , .gif";
        if (validFileType.toLowerCase().indexOf(extension) < 0) {
            return false;
        }
        else {
            return true;
        }
    };     

    $scope.uploadFile = function (event) {
        angular.forEach(event.target.files, function (value) {
            if (!fileService.exists(value.name)) {            
                fileService.add(value);
                $scope.uploadedFile = value.name;
                $scope.c.FileReferences.push({ Name: value.name, MimeType: value.type, IsNew: true, IsImage: isImage(value.name) });
            }
        });

        $scope.$apply();
    };
}])