app.controller('timePickerController', ['$scope', 'translationFlowClientService', function ($scope, translationFlowClientService) {

    var validate = function(currentText, kendoValue) {
        if (kendoValue) { // kendo verified the value is correct, do no invalidate
            return true;
        } else if ($scope.c.AllowEmpty) { // we allow empty
            return true; // its true even if it is empty or not
        } else { // we dont allow empty
            return currentText && currentText.length > 0;
        }    
    };

    $scope.textValidation = function (c) { 
        var format24 = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;
        var formatAmPm = /^(1[012]|[1-9]):[0-5][0-9](\s){0,1}(am|pm)$/i;
        var text = c == null ? "" : c.trim();

        if (text && typeof text == 'string' && text.length > 0) {  // if the text is not empty, and it is text
            if (text.match(format24) || text.match(formatAmPm)) {  // the format is HH:mm or HH:mm (AM/PM)
                var date = kendo.parseDate(text);
                $scope.c.validationClass = "";

                var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                $scope.c.Value = hours + ":" + minutes;

                return true;
            } else { // the format is wrong
                $scope.c.validationClass = "invalid";
                return false; 
            }
        } else { // if the text is empty
            if ($scope.c.AllowEmpty) { // empty is allowed - should not happen but just in-case ( check in onChange )
                $scope.c.validationClass = "";
                $scope.c.Value = "";
                return true;
            } else { // empty is not allowed
                $scope.c.validationClass = "invalid";
                return false;
            }
        }
    }

    $scope.onChange = function (value) {
        if ($scope.c.AllowEmpty && (value == null || value.length === 0)) {
            $scope.c.validationClass = "";
            $scope.c.Value = "";
        } else {
            $scope.textValidation(value);
        }
    };
    
    $scope.onBlur = function () {
        // if validation class is not empty when you leave focus, we reset it to the previous
        // most valid value, so that it becomes "valid" again.
        if ($scope.c.validationClass !== "")
            $scope.setDisplayValue();
    }

    $scope.setDisplayValue = function () {
        if ($scope.c.Value.length > 0) {
            var d = new Date();
            var time = $scope.c.Value.match(/(\d+)(?::(\d\d))?\s*(p?)/);
            d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
            d.setMinutes(parseInt(time[2]) || 0);
            $scope.displayValue = kendo.toString(d, "t");
            $scope.c.validationClass = "";
        } else {
            $scope.displayValue = "";
        }
    }

    $scope.initialize = function () {
        $scope.setDisplayValue();

        $scope.timeOptions = {
            interval: 15,
            change: function () {
                if (validate($scope.displayValue, this.value())) { // if validation returns true
                    if (this.value()) {  // the text is in a kendo value format
                        $scope.c.Value = this.value() ? kendo.toString(this.value(), "HH:mm") : "";
                        $scope.c.validationClass = "";
                    } else { // text is a user entered format.
                        $scope.onChange($scope.displayValue);
                    }
                } else { // if validation returns false
                    $scope.c.validationClass = "invalid";
                }
                $scope.$apply();
            }
        };
    };

    $scope.initialize();
}])