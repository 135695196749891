app.controller('itemCreationSubTaskController', ['$scope', 'stateService', 'executionService', 'workflowThemeService', 'translationFlowClientService', 'imageService', 'userStepDataService', function ($scope, stateService, executionService, workflowThemeService, translationFlowClientService, imageService, userStepDataService) {

    var createSubWorkflowData = function() {
        var data = {};
        data.WebCompletedExecution = {};
        data.JobIdentity = $scope.$parent.$parent.JobIdentity;
        data.SubTaskIdentity = $scope.$parent.item.Identity;
        data.WebCompletedExecution.Controls = userStepDataService.getLimitedUserStepData($scope.model.activeStep.Rows);
        data.WebCompletedExecution.Identifier = $scope.model.activeStep.Identifier;
        return data;
    };

    $scope.create = function (text) {
        var data = createSubWorkflowData();        
        executionService.startSubworkflow(data).then(function (result) {
            stateService.go($scope, 'execution', { executionIdentity: result.data.JobId, isHandover: false, name: text });
        }).catch(angular.noop);
    }

    $scope.resume = function (item, text) {
        if(item.IsRemovable) {
            var data = createSubWorkflowData();       
            data.ErrandTaskIdentity = item.Identity;
            executionService.resumeItemCreation(data).then(function (result) {
                stateService.go($scope, 'execution', { executionIdentity: result.data.JobId, isHandover: false, name: text, isItemCreation: true });
            }).catch(angular.noop);
        }        
    };

    $scope.remove = function (item) {
        var itemIndex = $scope.$parent.item.Errands.indexOf(item);
        executionService.removeItemCreation($scope.$parent.$parent.JobIdentity, $scope.$parent.item.Identity, itemIndex).then(function () {
            $scope.$parent.item.Errands.splice(itemIndex, 1);
        }).catch(angular.noop);
    }

    $scope.initialize = function () {
        $scope.workflowThemeService = workflowThemeService;
        $scope.imageService = imageService;
        $scope.themeColor = workflowThemeService.getColor();
        $scope.translationFlowClientService = translationFlowClientService;
    };

    $scope.initialize();
}])