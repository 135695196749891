app.controller("historyListContoller", [
    '$scope', 'stateService', 'translationFlowClientService', 'historyService', function ($scope, stateService, translationFlowClientService, historyService) {
            
        $scope.$watchCollection('model.history', function (newVal) {
            var indexOfOldestReachableStep = $scope.model.oldestReachableHistoryStepId ? historyService.findIndex(newVal, function (step) { return step.StepId === $scope.model.oldestReachableHistoryStepId }) : newVal.length;
            angular.forEach(newVal, function (item, index) {
                item.enableBackButton = index >= indexOfOldestReachableStep;
                item.showLine = index < newVal.length - 1;
            });
            $scope.model.showHistory = false;
        });

        $scope.goBack = function (backToItem) {
            $scope.model.showHistory = false;
            $scope.moveBack(backToItem.StepId);
        };

        $scope.closeHistory = function() {
            $scope.model.showHistory = false;
            historyService.hideHistoryList();
        };

        $scope.toggleShowHistory = function () {
            if ($scope.model.history && $scope.model.history.length > 0)
                $scope.model.showHistory = !$scope.model.showHistory;

            if($scope.model.showHistory )
                historyService.showHistoryList();
            else
                historyService.hideHistoryList();
        };

        $scope.$on('changeStateCompleted', function () {
            $scope.initialize();
        });

        $scope.$on('hideHistoryList', function () {       
            $scope.closeHistory();
        });
     

        $scope.initialize = function () {
            $scope.model.showHistory = false;
            $scope.stateService = stateService;
            $scope.historyService = historyService;
            $scope.translationFlowClientService = translationFlowClientService;
        };

        $scope.initialize();
    }
]);