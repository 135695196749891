app.controller("homeInboxController", [
    "$scope", "$interval", '$timeout', "workflowService", 'workflowThemeService', function ($scope, $interval, $timeout, workflowService, workflowThemeService) {
        
      
        $scope.loadOngoingExecutions = function () {
            $scope.getOngoingExecutions();
        };
        $scope.reloadOngoingExecutions = function () {
            $scope.getOngoingExecutions();
        };
        $scope.getOngoingExecutions = function () {
            $scope.workflowService.getOngoingExecutions(false).then(function (result) {
                $scope.model = angular.fromJson(result.data);
                $scope.inboxCount = $scope.model.length;
            }).catch(angular.noop);
        };
        $scope.initialize = function () {
            $scope.model = [];
            $scope.inboxCount = 0;
            $scope.workflowService = workflowService;
            $scope.loadOngoingExecutions();
            $scope.reload = $interval($scope.reloadOngoingExecutions, 60000);
        };
        $scope.$watch('currentNoOfOngoingExecutions', function (newVal, oldVal) {
            var date = new Date();
            date = kendo.toString(date, "HH:MM:ss.") + kendo.toString(date.getMilliseconds(), "000");
            if (newVal !== oldVal) {
                $scope.infoNotification.show(date, "info");
            }
        });
        $scope.$on('$destroy', function () {
            $interval.cancel($scope.reload);
        });

        $scope.initialize();

    }
])