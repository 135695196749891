app.controller('fileGalleryController', ['$scope', '$http', 'fileService', '$uibModal', 'translationFlowClientService',
    function ($scope, $http, fileService, $uibModal, translationFlowClientService) {
        var lastClickTimestamp = 0;
        for (var i = 0; i < $scope.c.Groups.length; i++) {
            var itm = $scope.c.Groups[i];
            itm.isOpen = !$scope.c.AutoShow;
        }

        var fileUploadId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { 
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : r & 0x3 | 0x8;
            return v.toString(16); 
        });

        var isValidFileExt = function (name, fileExts) {
            var extension = name.substring(name.lastIndexOf('.')).toLowerCase();
            var validFileType = fileExts.toLowerCase();
            return validFileType.length === 0 || validFileType.toLowerCase().indexOf(extension) > -1;
        };

        var isImage = function (name) {
            return isValidFileExt(name, ".jpg , .jpeg , .png , .bmp , .gif");
        };

        $scope.selectedImageSizeFromDropdown = 0;
        $scope.sizeOptions =
        [
            { value: 0, text: translationFlowClientService.get('SizeActualSize') },
            { value: 1, text: translationFlowClientService.get('SizeSmall') },
            { value: 2, text: translationFlowClientService.get('SizeMedium') },
            { value: 3, text: translationFlowClientService.get('SizeLarge') }
        ];

        $scope.options = {
            dataTextField: "text",
            dataValueField: "value",
            valuePrimitive: true,
            optionLabel: translationFlowClientService.get('SizeSelect'),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (opts) {
                        opts.success($scope.sizeOptions);
                    }
                }
            }),
            change: $scope.getDropdownSelectedValue
        };

        $scope.selectedImageSizeMode = function () {
            return $scope.c.SelectedImageSizeMode;
        };

        // applies to image resizing
        if ($scope.selectedImageSizeMode() === 4) {
            // create drop down
            $scope.visibleDropdown = true;
        }
        else if ($scope.selectedImageSizeMode() !== 4) {
            // add only two labels
            $scope.visibleDropdown = false;
        }

        $scope.selectedImageSizeModeName = getKeyByValue($scope.selectedImageSizeMode())[0];

        $scope.fileExtensions = function () {
            var fileExts = [];
            angular.forEach($scope.c.AllowedSuffixes, function (ext) {
                fileExts.push("." + ext);
            });
    
            return fileExts.join(", ");
        };

        $scope.allowMultiple = function () {
            return $scope.c.AllowAddMany;
        };

        $scope.download = function (item, templateId, event) {
            if (event && event.timeStamp) {
                if (event.timeStamp === lastClickTimestamp) // if identical to other just ignore (double click or remove+download issue)
                    return
                lastClickTimestamp = event.timeStamp; // if not last, just update it.
            }

            if (item.IsNew) {
                saveAs(fileService.get(item.Name), item.Name);
            } else {
                var data = {};
                data.ExecutionIdentity = $scope.JobIdentity;
                data.TemplateId = templateId;
                data.Name = item.Name;
                data.SelectedImageSizeMode = $scope.c.SelectedImageSizeMode;
    
                fileService.download(data).then(function (result) {
                    var file = new Blob([result.data], { type: item.MimeType });
                    saveAs(file, item.Name);
                }).catch(angular.noop);
            }
        };

        $scope.askImageSize = function () {
            return $scope.c.AskImageSize;
        };

        $scope.canAdd = function() {
            if ($scope.c.AllowAdd) {
                if ($scope.c.AllowAddMany) {
                    return true;
                } else {
                    return !hasFileAdded();
                }
            }
            return false;
        };
    
        function hasFileAdded() {
            for (var i = 0; i < $scope.c.FileReferences.length; i++) {
                if ($scope.c.FileReferences[i].IsNew)
                    return true; // if any file is "newly added" then we have a file added.
            }
            return false;
        }

        $scope.getInputId = function () {
            return fileUploadId;
        };

        function getKeyByValue(value) {
            return $scope.sizeOptions.filter(function (x) {
                return x.value === value;
            });
        }

        // checking by name is bad since they are not unique, but it is too much work to change this behavior for now.
        $scope.remove = function (name, event) {
            if (event && event.timeStamp) {
                if (event.timeStamp === lastClickTimestamp) // if identical to other just ignore (double click or remove+download issue)
                    return
                lastClickTimestamp = event.timeStamp; // if not last, just update it.
            }

            var isNew = false;
            for (var i = $scope.c.FileReferences.length - 1; i >= 0; i--) {
                if ($scope.c.FileReferences[i].Name === name && $scope.c.FileReferences[i].IsNew) {
                    isNew = true;
                    break;
                }
            }
                
            var data = { // the data required for the request towards the server if it is a new file that can be removed.
                ExecutionIdentity: $scope.JobIdentity,
                TemplateId: $scope.c.TemplateId,
                Name: name,
                IsNew: isNew
            };
                
            fileService.remove(data);
            
            for (var i = $scope.c.FileReferences.length - 1; i >= 0; i--) {
                if ($scope.c.FileReferences[i].Name === name && $scope.c.FileReferences[i].IsNew) // only removed "newly added" items.
                    $scope.c.FileReferences.splice(i, 1);
            }
        };

        $scope.uploadFile = function (event) {

            // if user defined size was set
            if ($scope.visibleDropdown) {
                $scope.c.UserDefinedSize = $scope.kdlid.value();
            }

            angular.forEach(event.target.files, function (value) {
                if (isValidFileExt(value.name, $scope.fileExtensions())) {
                    // TODO : Should maybe make the file getting (1), (2) if the name already exists in $scope.c.FileReferences ( would resolve the "remove" issues of duplicated file names)
                    // Especially since the fileservice may not contain the file after you've pressed "moveforward" as it clears the "fileservice" files stack.
                    if (!fileService.exists(value.name)) {
                        fileService.add(value);
                    }

                    $scope.c.FileReferences.push({ Name: value.name, MimeType: value.type, IsNew: true, IsImage: isImage(value.name) });
                }
            });

            $scope.$apply();

            // Clear value solves NFSIX-1235
            event.target.value = null;
        };

        $scope.expandCollapse = function (isOpen) {
            return !isOpen;
        };
    }
]);