app.controller('loginController', ['$scope', '$rootScope', '$location', "$uibModal",
	'authenticationService', 'sharedSessionService', 'translationFlowClientService', '$timeout',
	'featuresService', '$q', '$routeParams', 'localizationService',
	function ($scope, $rootScope, $location, $uibModal, authenticationService,
		sharedSessionService, translationFlowClientService, $timeout, featuresService,
		$q, $routeParams, localizationService) {

		var requestObj = { scopes: ["user.read"] };
		var userGraphCallURI = "https://graph.microsoft.com/v1.0/me";

		$scope.showScreen = false;
		$scope.stopSpam = false;
		$scope.bearerToken = null;

		
		$scope.getCredentials = function () {
			$scope.authenticationService.getCredentials($scope.model.username).then(function (result) {
				$scope.model.credentials = result.data;
			}).catch(angular.noop);
		};

		$scope.showSSOLink = function () {
			return $.enableSSO && !$.openIdActive;
		};

		$scope.showAADALink = function () {
			return $.enableAADA && ecmaScript6Supported; //ecmaScript6Supported is a cool global variable created in even cooler msal-hook.js, not very nice
		};


		$scope.showUserNameLogin = function () {
			return !$.openIdActive || $.allowUserPasswordLogin;
		};

		$scope.showOpenidLogin = function () {
			return $.openIdActive;
		};

		$scope.openIdLogin = function () {
			authenticationService.loginUsingOpenId($scope);
		};

		$scope.ssoLogin = function () {
			$scope.authenticationService.ssoLogin().then(function (result) {

				if (result.data.Result) {
					sharedSessionService.set(angular.fromJson(result.data.Result));

					authenticationService.restoreSession().then(function (result) {

						sharedSessionService.update(result.data);
						localizationService.setLocalization(result.data.LanguageCode, result.data.Culture);

						if ($rootScope.urlPath)
							$location.path($rootScope.urlPath);
						else
							$location.path("/");

					}).catch(function (e) {
						console.log(e);
					});
				}
			}).catch(function (e) {
				console.log(e);
			});
		};

		function activateLoginBtn() { $scope.stopSpam = false; }

		function callMSGraph(theUrl, accessToken, callback) {
			var xmlHttp = new XMLHttpRequest();
			xmlHttp.onreadystatechange = function () {
				if (this.readyState === 4 && this.status === 200)
					callback(JSON.parse(this.responseText));
			};

			xmlHttp.open("GET", theUrl, true); // true for asynchronous
			xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);
			xmlHttp.send();
		}


		var replaceIndexWithCharacter = function (mrString, index, replacement) {
			return mrString.substr(0, index) + replacement + mrString.substr(index + replacement.length);
		};

		function populateUserNameAndLoginCallback(data) {
			var userName = data.userPrincipalName;
			var GUEST_USER_THING = "#EXT#";

			//ted.lilljegren_novacura.se#EXT#@novacuratest.onmicrosoft.com
			//^ this is how a guest user's principalname looks
			if (userName.includes(GUEST_USER_THING)) { //this naming convention is used for guest users
				userName = userName.split(GUEST_USER_THING)[0];

				//If the user was a guest user, they would have their @ replaced with a _
				//A user principalname may have _, we're always going to replace the last one and hope the email domain does not have an _
				var lastUnderscoreIndex = userName.lastIndexOf("_");
				//there will always be at least one _ when it's a 
				userName = replaceIndexWithCharacter(userName, lastUnderscoreIndex, "@");
			}
			if (!$.AADAIncludeDomain) {
				$scope.model.hiddenUsername = userName.split("@")[0];
			} else {
				$scope.model.hiddenUsername = userName;
			}

			$scope.login();
		}

		function requiresInteraction(errorCode) {
			if (!errorCode || !errorCode.length) {
				return false;
			}
			return errorCode === "consent_required" ||
				errorCode === "interaction_required" ||
				errorCode === "login_required" ||
				errorCode === "null_or_empty_id_token";
		}

		function acquireTokenPopupAndCallMSGraph() {
			//Always start with acquireTokenSilent to obtain a token in the signed in user from cache
			//userAgentApplication.clearCache();
			
			userAgentApplication.acquireTokenSilent(requestObj).then(function (tokenResponse) {
				$scope.model.bearerToken = tokenResponse.accessToken;
				console.log("silent token acquisition succeeds");
				callMSGraph(userGraphCallURI, tokenResponse.accessToken, populateUserNameAndLoginCallback);
				console.log("Call msGraphSucceeds");
			}).catch(function (error) {
				console.log(error);
				console.log("silent token acquisition fails. acquiring token using popup");
				// Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
				// Call acquireTokenPopup(popup window)
				if (requiresInteraction(error.errorCode)) {
					userAgentApplication.acquireTokenPopup(requestObj).then(function (tokenResponse) {
						console.log("acquireTokenPopup succeds.");
						$scope.model.bearerToken = tokenResponse.accessToken;
						callMSGraph(userGraphCallURI, tokenResponse.accessToken, populateUserNameAndLoginCallback);
					}).catch(function (error) {
						console.log(error);
						console.log("nothing works");
					});
				}
			});
		}

		$scope.msalLogin = function () {
			userAgentApplication.loginPopup(requestObj).then(function (loginResponse) {
				acquireTokenPopupAndCallMSGraph();
			},
				function (error) {
					console.log("error: " + error);
				}).catch(angular.noop);
		};

		$scope.getUsername = function () {
			var userName = $scope.model.hiddenUsername ? $scope.model.hiddenUsername : $scope.model.username;
			$scope.model.hiddenUsername = null;
			return userName;
		};

		$scope.login = function () {
			$scope.stopSpam = true;
			$scope.activateButton = $timeout(activateLoginBtn, 5000);
			var userName = $scope.getUsername();
			$scope.authenticationService.getCredentials(userName).then(function (result) {
				$scope.model.credentials = result.data.ExternalCredentials;
				$scope.model.openIdConfig = result.data.OpenIdConfiguration;
				$scope.model.openIdInfo = result.data.OpenIdInfo;
				$scope.model.passwordRequired = result.data.FlowPasswordRequired;

			}).then(function () {
				if ($scope.model.credentials.length === 0 && !$scope.model.passwordRequired) {
					$scope.authenticationService.login(userName, $scope.model.password, $scope.model.bearerToken, $scope.model.credentials).then(function (result) {

						sharedSessionService.set(angular.fromJson(result.data));
						localizationService.setLocalization(result.data.LanguageCode, result.data.Culture);

						if ($rootScope.urlPath)
							$location.path($rootScope.urlPath);
						else
							$location.path("/");
					});
				}
				else {

					var modalInstance = $uibModal.open({
						templateUrl: $.sharedAppDir + "/views/credentials.html",
						controller: 'credentialsController',
						backdrop: 'static',
						keyboard: true,
						resolve: {
							data: function () {
								return $scope.model;
							}
						}
					});

					modalInstance.result.then(function () {
						$scope.model.password = null;
					}, function () {
						//handle popup dismiss on cancel
					}).catch(angular.noop);
				}
			}).catch(function (ex) {
				console.log(ex);
			});
		};

		$scope.$on('$destroy', function () {
			$timeout.cancel($scope.activateButton);
		});

		$scope.initialize = function () {

			//hack for portal- for some reason we lost focus on portal login page
			$timeout(function () {
				angular.element('#user').focus();
			}, 400);

			$scope.model = {};
			$scope.model.credentials = [];
			$scope.model.username = "";
			$scope.model.hiddenUsername = ""; //this field is not associated with any web form controls, so setting this field when logging in with MSAL will not populate the login field
			$scope.model.password = "";
			$scope.model.bearerToken = "";
			$scope.model.passwordRequired = false;
			$scope.authenticationService = authenticationService;
			$scope.featuresService = featuresService;
			$scope.translationFlowClientService = translationFlowClientService;

			var promises = [];

			promises.push($scope.translationFlowClientService.getAll(sharedSessionService.getLanguageCode()));
			promises.push($scope.featuresService.getAllFeatureFlags());

			$q.all(promises)
				.then(function () {
					$scope.showScreen = true;
				}).catch(angular.noop);

			var params = $routeParams;
			var search = $location.search();
			var autoLogin = search.autoLogin;
			var useAzure = search.useAzure;

			$location.search("autoLogin", null);
			$location.search("useAzure", null);

			if (params.userName) {
				$scope.publicUserAutoLogin();
			} else if (autoLogin) {
				if (useAzure === "true") {
					$scope.msalLogin();
				} else {
					$scope.ssoLogin();
				}
			}

		};

		$scope.publicUserAutoLogin = function () {

			var data = {};
			data.StartParameters = [];

			var search = $location.search();

			angular.forEach(search, function (value, key) {
				data.StartParameters.push(key, value);
			});

			data.WorkflowIdentity = $routeParams.workflowIdentifier;

			if ($routeParams.userName) {
				authenticationService.joinTransactionSession($routeParams.userName).then(function (joinResult) {
					sharedSessionService.set(joinResult.data);

					localizationService.setLocalization(joinResult.data.LanguageCode, joinResult.data.Culture);

					var search = {};
					for (var key in $routeParams) {
						if (key !== 'userName') search[key] = $routeParams[key];
					}

					$location.search(search);
					$location.path('/');
				});
			}
			else {
				executionService.startWithParameters(data).then(function (result) {
					$location.path('/');
				});
			}
		};

		$scope.initialize();

	}]);