app.controller("credentialsController", [
	"$scope", '$rootScope', '$location', '$uibModal', '$uibModalInstance', 'data',
	'authenticationService', 'translationFlowClientService', 'sharedSessionService','localizationService',
    '$timeout', "ultimateOpenIdService", function ($scope, $rootScope, $location, $uibModal, $uibModalInstance, data, authenticationService, translationFlowClientService, sharedSessionService, localizationService, $timeout, ultimateOpenIdService) {

        $scope.initialize = function () {
            $timeout(function () {
                $('#credentialWindow').find('input').first().focus();
                $(".modal-dialog").css({
                    'min-width' : '360px',
                    'max-width' : '800px'
                 });
            }, 200);

			$scope.authenticationService = authenticationService;
            $scope.translationFlowClientService = translationFlowClientService;
            $scope.model = data;
        };


		$scope.loginOpenId = function (config) {
            ultimateOpenIdService.authorize(config, $scope.model.username, $scope.model.password, $scope.model.bearerToken, $scope.model.credentials);
		};

		$scope.login = function () {
            if ($scope.model.openIdLogin) {
                $scope.openIdLogin();
            } else {
                $scope.normalLogin();
            }
        };

        $scope.normalLogin = function () {
            var openIdInfo = ultimateOpenIdService.getOpenIdInfo();

            $scope.authenticationService.login($scope.model.username, $scope.model.password, $scope.model.bearerToken, $scope.model.credentials, openIdInfo).then(function (result) {
                sharedSessionService.set(angular.fromJson(result.data));

                localizationService.setLocalization(result.data.LanguageCode, result.data.Culture);

                if ($rootScope.urlPath)
                    $location.path($rootScope.urlPath);

                else
                    $location.path("/");

                $uibModalInstance.close();
            }).catch(angular.noop);
        };

        $scope.openIdLogin = function () {
            ultimateOpenIdService.sendLoginRequest($scope);
        };

        $scope.cancel = function () {
            $uibModalInstance.close();
        };

        $scope.initialize();
    }
]);

