app.controller('linkController', ['$scope', function ($scope) {
   
    $scope.initialize = function () {

        $scope.IsEmail = $scope.c.LinkType == 'Email';
        $scope.IsPhone = $scope.c.LinkType == 'Phone';
        $scope.IsAddress = $scope.c.LinkType == 'Address';
           
        $scope.IsRegularLink = !($scope.IsEmail || $scope.IsPhone || $scope.IsAddress);

        $scope.Uri = $scope.ValidatedUri($scope.c.Uri);
 
        if ($scope.IsAddress)
            $scope.addressUri = "http://maps.google.com/?q=" + $scope.c.Uri.replace(/\|/g, " ");
       
        if ($scope.IsPhone)
            $scope.phoneNumber = "tel:" + $scope.c.Uri ;
    };

    $scope.ValidatedUri = function (uri)
    {
        var result = uri;
        if (!uri.match(/^[a-zA-Z]+:\/\//)) {
            result = 'http://' + uri;
        }  
        return result;
    }
    
    $scope.initialize();

}])