app.controller('checkBoxController', ['$scope', function ($scope) {

    $scope.generateControlRandomKey = function () {

        var result = Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);

        return result;
    };

    $scope.initialize = function () {

        $scope.controlKey = $scope.generateControlRandomKey();

    };
    $scope.initialize();
}])