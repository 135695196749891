app.controller("breadcrumbsController", [
    '$scope', '$injector', 'stateService', function ($scope, $injector, stateService) {

        $scope.$on('changeStateCompleted', function () {
            $scope.initialize();
        });

        $scope.goTo = function(idx) {

            if(stateService.inItemCreationSubworkflow($scope)) {
                var modal = $injector.get('$uibModal');
                var modalInstance = modal.open({
                        templateUrl: $.sharedAppDir + "/views/confirmDialog.html",
                        controller: 'confirmDialogController',
                        backdrop: 'static',
                        size: 'confirm-dialog-size',
                        keyboard: false,
                        resolve: {
                            dialogResult: function () {
                                return function (result) 
                                    { 
                                        if(result) 
                                            stateService.goBackToIndex($scope, idx);
                                    
                                    };
                            }
                        }
                    });
            } else {
                stateService.goBackToIndex($scope, idx);
            }            
        };

        $scope.goToPrevious = function() {
            $scope.goTo($scope.breadcrumbs.length - 2);
        };

        $scope.initialize = function () {

            $scope.stateService = stateService;

            $scope.breadcrumbs = [];

            var history = stateService.getHistory($scope);
            for (var i = 0; i < history.length; i++) {
                if (history[i].params && history[i].params.name) {
                    $scope.breadcrumbs.push({ name: history[i].params.name, index: i });
                }
            }
        };

        $scope.initialize();
    }
]);
