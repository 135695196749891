app.controller('imageSelectionController',
[
    '$scope', '$filter', function($scope, $filter) {
        var CELLS_PER_VIEW = 8;

        var currentViewIndex = 0;

        var setSelectionOnCell = function(cell, value) {
            cell.IsSelected = value;
        }

        $scope.onImageSelectionCellClick = function (cell) {
            setSelectionOnCell(cell, !cell.IsSelected);

            // Deselect all other cells
            if (!$scope.c.MultiSelect) {
                angular.forEach($scope.c.Cells,
                    function(c) {
                        if (c.RowIndex !== cell.RowIndex)
                            setSelectionOnCell(c, false);
                    });
            }

            $scope.c.SelectedIndices = [];
            angular.forEach($scope.c.Cells,
                    function (c) {
                        if (c.IsSelected)
                            $scope.c.SelectedIndices.push(c.RowIndex);
                    });
            console.log($scope.c.SelectedIndices);
        };

        var getCurrentCells = function() {
            if ($scope.c.Cells.length === 0)
                return [];
            else if ($scope.showRightArrow())
                return $scope.c.Cells.slice(currentViewIndex * CELLS_PER_VIEW,
                    (currentViewIndex + 1) * CELLS_PER_VIEW);
            else
                return $scope.c.Cells.slice(currentViewIndex * CELLS_PER_VIEW);
        };

        $scope.showLeftArrow = function() {
            return currentViewIndex > 0;
        };

        $scope.showRightArrow = function() {
            return $scope.c.Cells.length > (currentViewIndex + 1) * CELLS_PER_VIEW;
        };

        $scope.goLeft = function() {
            currentViewIndex = Math.min(0, currentViewIndex - 1);
            $scope.currentCells = getCurrentCells();
        };

        $scope.goRight = function() {
            currentViewIndex++;
            $scope.currentCells = getCurrentCells();
        };

        $scope.initialize = function() {
            $scope.currentCells = getCurrentCells();
        };

        $scope.initialize();

    }
]);